import { Loader } from "components/Loader/Loader";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useGoalsList from "shared/api/useGoalsList";
import useMaterialsSkill from "shared/api/useMaterialsSkill";
import useSkillsGoal from "shared/api/useSkillsGoal";
import useUser from "shared/api/useUser";
import { ActiveThreatType, TrajectoryActiveType } from "shared/types";
import "./index.css";
import { PopupTest } from "components/PopupTest/PopupTest";
import * as types from "shared/types";
import { LineDiagram } from "components/LineDiagram/LineDiagram";
import { Events } from "components/Calendar/Events";
import { Calendar } from "components/Calendar/Calendar";
import weekProgressPic from "shared/ui/icon/weekProgress.svg";
import useAllThreatsList from "shared/api/useAllThreatsList";
import { useStore } from "./model";
import useUserMounthProgress from "shared/api/useUserMounthProgress";
import { useQuery } from "@tanstack/react-query";
import * as http from "shared/http";
import { useSessionPopup } from "components/MaterialSessionPopup/model";

function ThreatCard({
    threat,
}: {
    threat: types.ActiveThreatType | undefined;
}) {
    const [daysStr, timeStr] = threat?.threat.threat_duration?.split(" ") || [
        "0",
        "00:00:00",
    ];
    const [daysReset, timeReset] = threat?.reset_time?.split(" ") || [
        "0",
        "00:00:00",
    ];

    let threatProgress = 0;
    if (threat?.quiz_result_score > 0) {
        threatProgress = threat?.quiz_result_score;
    } else if (threat?.completed_content_count > 0) {
        threatProgress =
            threat?.completed_content_count / threat?.content_count;
    }
    const skillPercent = (threatProgress * 100).toFixed(0);
    function timeStringToMilliseconds() {
        const days = Number(daysStr);
        const parts = timeStr?.split(":").map(Number);
        if (parts.length !== 3) {
            throw new Error("Invalid time format. Please use 'hh:mm:ss'");
        }
        const [hours, minutes, seconds] = parts;
        if (isNaN(days) || isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
            throw new Error(
                "Invalid time format. Please use numbers for hours, minutes, and seconds."
            );
        }
        return (((days * 24 + hours) * 60 + minutes) * 60 + seconds) * 1000;
    }

    function durationToMs() {
        try {
            return timeStringToMilliseconds();
        } catch (error) {
            // console.error(error.message);
            return 0;
        }
    }

    const durationMs = durationToMs();

    const ddd = new Date(
        Date.now() + durationMs * (1 - threat?.duration_percent)
    );

    const day = ddd.getDate();
    const mounth = ddd.getMonth();
    const year = ddd.getFullYear();
    const dedline = `${day}.${mounth + 1}.${year}`;
    const navigate = useNavigate();
    const handleLinkGoal = () => {
        navigate(`timeline`);
    };

    const openActiveMaterial = useSessionPopup((state) => state.open);

    const handleClickLinkThreat = (threatId: number) => {
        if (typeof threatId !== "number") {
            throw new Error("invalid threatId");
        }
        openActiveMaterial(null, threatId, null);
    };

    return (
        <div className="flex flex-col pt-[20px]">
            <p className="text-tr-m font-medium text-[#264354]">
                Продолжите изучение
            </p>
            <p className="mt-[5px] text-tr-s font-normal text-[#264354CC] opacity-80">
                У вас есть незавершенный навык
            </p>
            <div className="mt-[20px] flex h-[255px] w-[455px] flex-col rounded-[20px] bg-[#369F48] bg-opacity-90 px-[15px] py-[20px] text-white">
                <div className="flex items-center justify-between">
                    <p className="text-tr-m font-medium text-white">
                        {threat?.threat.name}
                    </p>
                    <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-white">
                        <svg
                            width="23"
                            height="28"
                            viewBox="0 0 23 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g filter="url(#filter0_d_12747_67584)">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M8.12729 23.4949C7.06166 21.947 7.34756 19.5057 8.42449 17.6173C8.48807 19.1417 8.8218 19.5161 9.53507 19.9881C9.0694 16.9287 9.31914 15.4284 11.4665 13.4685C11.1583 14.3095 11.3751 16.1353 13.3014 17.4691C14.2708 18.1109 15.4246 19.6157 15.3294 21.3216L15.3261 21.3807C15.2828 22.1629 15.2358 23.0098 14.808 23.6879C19.1511 21.6935 19.6032 16.2209 16.7691 11.2305C16.6588 13.8872 16.0796 14.5398 14.8417 15.3622C15.6499 10.0304 15.2165 7.4157 11.4897 4C12.0246 5.46576 11.6484 8.64767 8.3053 10.9723C6.623 12.0908 4.62059 14.7132 4.7857 17.6863C4.93136 20.3091 6.01712 22.2757 8.12729 23.4949Z"
                                    fill="url(#paint0_linear_12747_67584)"
                                />
                            </g>
                            <defs>
                                <filter
                                    id="filter0_d_12747_67584"
                                    x="-1"
                                    y="0"
                                    width="26"
                                    height="28.25"
                                    filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB"
                                >
                                    <feFlood
                                        flood-opacity="0"
                                        result="BackgroundImageFix"
                                    />
                                    <feColorMatrix
                                        in="SourceAlpha"
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha"
                                    />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite
                                        in2="hardAlpha"
                                        operator="out"
                                    />
                                    <feColorMatrix
                                        type="matrix"
                                        values="0 0 0 0 1 0 0 0 0 0.305882 0 0 0 0 0.14902 0 0 0 0.25 0"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow_12747_67584"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow_12747_67584"
                                        result="shape"
                                    />
                                </filter>
                                <linearGradient
                                    id="paint0_linear_12747_67584"
                                    x1="11.6663"
                                    y1="4"
                                    x2="11.6663"
                                    y2="23.6879"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stop-color="#FF4E26" />
                                    <stop offset="1" stop-color="#FFBEAF" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
                {threat?.duration_percent !== null && (
                    <p className="mt-[10px] text-tr-s font-medium">
                        Изучить до {dedline}
                    </p>
                )}

                <div className="mt-[20px] flex items-center">
                    <div className="flex flex-col items-center">
                        <p className="text-tr-xxxxl font-medium ">
                            {skillPercent}%
                        </p>
                        <p className="text-tr-s font-normal ">
                            Прогресс освоения
                        </p>
                    </div>
                    <div className="ml-[20px] flex flex-col gap-y-[10px]">
                        <div className="flex items-center gap-x-[10px]">
                            <p className="text-tr-l font-medium">
                                {threat?.content_count}
                            </p>
                            <p className="text-tr-s font-normal">
                                Учебных матeриалов
                            </p>
                        </div>
                        <div className="flex items-center gap-x-[10px]">
                            <p className="text-tr-l font-medium">12</p>
                            <p className="text-tr-s font-normal">
                                Проверочных матeриалов
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    onClick={() => handleClickLinkThreat(threat.id)}
                    className="mt-[20px] flex h-[35px] w-full cursor-pointer items-center justify-center rounded-full bg-white text-tr-s font-medium text-[#369F48]"
                >
                    Продолжить изучение
                </div>
            </div>
            {threat?.reset_time !== null && (
                <>
                    <p className="mt-[15px] flex text-tr-m font-medium text-[#264354]">
                        Подтверждение навыка через{" "}
                        <p className="ml-[5px] flex text-tr-m font-medium text-[#F37D73]">
                            {Number(daysReset) / 30} месяцев
                        </p>
                    </p>
                    <p className="mt-[10px] text-tr-s font-normal text-[#264354CC] opacity-80">
                        Потребуется подтверждение от Администратора или куратора{" "}
                    </p>
                </>
            )}

            <div
                onClick={handleLinkGoal}
                className="ml-auto mt-auto flex cursor-pointer items-center gap-x-[5px]"
            >
                <p className="text-tr-s font-normal text-[#264354] underline">
                    Смотреть все
                </p>
                <svg
                    width="16"
                    height="9"
                    viewBox="0 0 16 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M15.3536 4.85355C15.5488 4.65829 15.5488 4.34171 15.3536 4.14645L12.1716 0.964466C11.9763 0.769204 11.6597 0.769204 11.4645 0.964466C11.2692 1.15973 11.2692 1.47631 11.4645 1.67157L14.2929 4.5L11.4645 7.32843C11.2692 7.52369 11.2692 7.84027 11.4645 8.03553C11.6597 8.2308 11.9763 8.2308 12.1716 8.03553L15.3536 4.85355ZM0 5L15 5V4L0 4L0 5Z"
                        fill="#264354"
                    />
                </svg>
            </div>
        </div>
    );
}

function transformSkills(
    skills: ActiveThreatType[],
    active: TrajectoryActiveType
) {
    const threatToCompletion: Record<
        number,
        { isAvailable: boolean; prev: number | null }
    > = {};
    if (skills !== undefined && active?.is_ordered === true) {
        for (const [i, active_threat] of skills.entries()) {
            if (i === 0) {
                threatToCompletion[active_threat.id] = {
                    isAvailable: true,
                    prev: null,
                };
            } else {
                const prevThreat = skills[i - 1];
                const nextElem = { isAvailable: false, prev: prevThreat.id };
                const prevAvailable =
                    threatToCompletion[prevThreat.id].isAvailable;
                const prevThreatCompletion =
                    prevThreat.content_count -
                    prevThreat.completed_content_count;
                if (prevAvailable && prevThreatCompletion === 0) {
                    nextElem.isAvailable = true;
                }

                threatToCompletion[active_threat.id] = nextElem;
            }
        }
    }

    return threatToCompletion;
}

interface OutputObject {
    [key: string]: {
        day: number;
        completed_materials: number;
        opened_materials: number;
    };
}

function MainPage() {
    const navigate = useNavigate();
    const sphereId = useStore((s) => s.sphereId);
    if (sphereId === null) navigate("/profile");
    const { user, isLoading: isUserLoading, isError: isUserError } = useUser();

    const { goalsList, isLoadingGoal } = useGoalsList();

    const LeftActiveGoalList = getData(goalsList?.results);

    const [activeIndex, setActiveIndex] = useState(0);
    const [threatIndex, setThreatIndex] = useState(0);

    const [width, setWidth] = useState(window.innerWidth);
    const [leftActive, setLeftActive] = useState<TrajectoryActiveType>();
    const [openPopupTest, setOpenPopupTest] = useState(false);

    const activeGoalList2 =
        goalsList?.results.filter((m) => m.id !== LeftActiveGoalList.id) || [];

    const active = activeGoalList2[activeIndex];

    const { skillList, isLoadingSkill } = useSkillsGoal(active?.id);

    function getData(activeGoalList: TrajectoryActiveType[]) {
        if (activeGoalList?.length > 0) {
            const filteredList = activeGoalList?.filter(
                (item) =>
                    item.completed_threats_count / item.threats_count !== 1
            );
            if (filteredList?.length === 0) {
                const LeftActiveGoalList = activeGoalList?.reduce((acc, curr) =>
                    acc.completed_threats_count[0] / acc.threats_count[1] >
                    curr.completed_threats_count[0] / curr.threats_count[1]
                        ? curr
                        : acc
                );
                return LeftActiveGoalList;
            } else {
                const LeftActiveGoalList = filteredList?.reduce((acc, curr) =>
                    acc.completed_threats_count[0] / acc.threats_count[1] >
                    curr.completed_threats_count[0] / curr.threats_count[1]
                        ? curr
                        : acc
                );
                return LeftActiveGoalList;
            }
        }
    }

    const threat = skillList?.results[threatIndex];

    const { materialsList, isLoadingMaterial } = useMaterialsSkill(threat?.id);

    const openActiveMaterial = useSessionPopup((state) => state.open);

    const handleClickLink = () => {
        if (materialsList?.results.length === 0) {
            setOpenPopupTest(true);
        } else {
            if (
                skillsToCompletionRight[threat?.id]?.isAvailable === true ||
                active?.is_ordered === false
            ) {
                if (typeof active.id !== "number") {
                    throw new Error("invalid activeId");
                }
                openActiveMaterial(null, threat?.id, null);
            }
        }
    };

    const skillsToCompletionRight = transformSkills(skillList?.results, active);

    let threatProgress = 0;
    if (threat?.quiz_result_score > 0) {
        threatProgress = threat?.quiz_result_score;
    } else if (threat?.completed_content_count > 0) {
        threatProgress =
            threat?.completed_content_count / threat?.content_count;
    }

    const { mounthProgress } = useUserMounthProgress();

    function processObjects(objects: types.MounthProgress[]): OutputObject {
        const result: OutputObject = {};
        objects?.forEach((obj) => {
            const dateOnly = obj.time_update.slice(8, 10);
            if (!result[dateOnly]) {
                result[dateOnly] = {
                    day: Number(dateOnly),
                    completed_materials: 0,
                    opened_materials: 0,
                };
            }

            if (obj.percent_progress >= 1.0) {
                result[dateOnly].completed_materials += 1;
            }
            result[dateOnly].opened_materials += 1;
        });

        return result;
    }

    const processedData = processObjects(mounthProgress);
    const backend_response = Object.values(processedData);

    const {
        data: sphereData,
        error: sphereError,
        status: sphereStatus,
    } = useQuery({
        queryKey: ["sphere", sphereId],
        queryFn: async () => {
            const response = await http.request<types.SphereType>({
                url: `/api/v1/activity-fields/${sphereId}/`,
            });
            return response;
        },
        enabled: sphereId !== null,
    });

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });

        if (LeftActiveGoalList !== undefined) {
            setLeftActive(LeftActiveGoalList);
        }
    }, [
        width,
        LeftActiveGoalList,
        materialsList,
        isLoadingGoal,
        isLoadingSkill,
        isLoadingMaterial,
    ]);

    const materialToCompletion: Record<
        number,
        { isAvailable: boolean; prev: number | null }
    > = {};

    let isQuizAvailable = threat?.is_external_quiz;

    if (materialsList !== undefined) {
        for (const [i, material] of materialsList.results.entries()) {
            if (i === 0) {
                materialToCompletion[material.id] = {
                    isAvailable: true,
                    prev: null,
                };
            } else {
                const prevMat = materialsList.results[i - 1];
                const nextElem = { isAvailable: false, prev: prevMat.id };
                const prevAvailable =
                    materialToCompletion[prevMat.id].isAvailable;

                if (prevAvailable && prevMat.session.percent_progress >= 100) {
                    nextElem.isAvailable = true;
                }

                materialToCompletion[material.id] = nextElem;
            }

            if (
                i === materialsList.results.length - 1 &&
                materialToCompletion[material.id]?.isAvailable
            ) {
                isQuizAvailable =
                    isQuizAvailable || material.session.percent_progress >= 100;
            }
        }
    }
    if (
        (materialsList == undefined || materialsList?.count == 0) &&
        threat?.quiz
    ) {
        isQuizAvailable = true;
    }

    function daysInSec(timeStr: string): string {
        const timer = " 00:00:00";
        const days = Number(timeStr?.replace(timer, ""));
        return String(days);
    }

    const allThreats = useAllThreatsList();
    const testList = allThreats?.allThreatsList?.results;
    function searchThreat(threats: ActiveThreatType[]) {
        let threatCard: ActiveThreatType = null;
        if (threats?.length === 0) return;

        const threatDeadline = threats?.filter(
            (threat) => threat.threat.threat_duration !== null
        );
        const threatOblivion = threats?.filter(
            (threat) => threat.reset_time !== null
        );
        const threatOther = threats;
        if (threatDeadline?.length !== 0) {
            const dedlineListWithSec = [];
            threatDeadline?.forEach((threat) => {
                const duration = daysInSec(threat.threat.threat_duration);
                threat.threat.threat_duration = duration;
                dedlineListWithSec.push(threat);
            });
            if (dedlineListWithSec?.length !== 0) {
                const threatFinish = dedlineListWithSec?.reduce((acc, curr) =>
                    Number(acc.threat.threat_duration) >
                    Number(curr.threat.threat_duration)
                        ? curr
                        : acc
                );
                threatCard = threatFinish;
            }
            return threatCard;
        } else if (threatOblivion?.length !== 0) {
            const oblivionListWithSec = [];
            threatOblivion?.forEach((threat) => {
                const duration = daysInSec(threat.reset_time);
                threat.reset_time = duration;
                oblivionListWithSec.push(threat);
            });
            if (oblivionListWithSec?.length !== 0) {
                const threatFinish = oblivionListWithSec?.reduce((acc, curr) =>
                    Number(acc.reset_time) > Number(curr.reset_time)
                        ? curr
                        : acc
                );
                threatCard = threatFinish;
            }
            return threatCard;
        } else {
            const ListProgress = [];
            threatOther?.forEach((threat) => {
                let threatProgress = 0;
                if (threat?.quiz_result_score > 0) {
                    threatProgress = threat?.quiz_result_score;
                } else if (threat?.completed_content_count > 0) {
                    threatProgress =
                        threat?.completed_content_count / threat?.content_count;
                }
                threat.quiz_result_score = threatProgress;
                ListProgress.push(threat);
            });

            if (ListProgress.length !== 0) {
                const threatFinish = ListProgress?.reduce((acc, curr) =>
                    acc.quiz_result_score < curr.quiz_result_score ? curr : acc
                );
                threatCard = threatFinish;
            }

            return threatCard;
        }
    }
    const itogThreat = searchThreat(testList);

    // TODO добавить крутилку
    if (isUserLoading || isUserError || isLoadingGoal)
        return <Loader text="Загрузка..." />;

    return (
        <section className="relative flex h-full w-full flex-col items-center overflow-y-scroll px-[5px] pb-[26px] max-[834px]:pb-[76px]">
            <div className="mt-[30px] flex w-full max-w-[1415px]">
                <div className="mr-[10px] flex w-full max-w-[1415px] flex-col gap-[65px] max-[834px]:gap-[60px]">
                    <div className="mb-[20px] flex flex-col">
                        <h2 className="text-[20px] font-bold leading-[24px] text-[#264354] max-[834px]:text-[16px] max-[834px]:leading-[19.2px]">
                            Здравствуй, {user?.first_name}!
                        </h2>
                        <p className="mt-[20px] text-tr-m font-normal text-[#264354]">
                            Сфера деятельности
                        </p>
                        <p className="trxt-[#264354] mt-[10px] text-tr-l font-medium">
                            {sphereData?.name}
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex w-full max-w-[1415px] gap-x-[25px]">
                <div className="flex flex-col gap-y-[20px]">
                    <div className="flex gap-x-[25px]">
                        {testList?.length === 0 ? (
                            <div className="flex h-[255px] w-[455px] flex-col items-center justify-center ">
                                <p>В данной сфере отсутствуют навыки</p>
                            </div>
                        ) : (
                            <ThreatCard threat={itogThreat} />
                        )}

                        <div className="relative flex h-[430px] w-[455px] flex-col rounded-[20px] shadow-[0_0_10px_0_rgba(38,67,84,0.1)]">
                            <div className="ml-auto flex w-full max-w-[220px] rounded rounded-tr-[20px] bg-[#26435433] px-[23px] py-[4px] text-tr-s font-normal text-[#264354]">
                                26 академических часов
                            </div>
                            <p className="pl-[20px] text-tr-m font-medium text-[#264354]">
                                Успехи недели
                            </p>
                            <p className="mt-[10px] pl-[20px] text-tr-s font-normal text-[#264354CC]">
                                Срез достижений за неделю
                            </p>
                            <div className="mt-[40px] flex flex-col gap-y-[40px] pl-[20px]">
                                <div className="flex items-center gap-x-[15px]">
                                    <p className="text-[53px] font-bold leading-[63px] text-[#F3A773]">
                                        1
                                    </p>
                                    <p className="text-tr-m font-normal text-[#264354]">
                                        Целей достигнуто
                                    </p>
                                </div>
                                <div className="flex items-center gap-x-[15px]">
                                    <p className="text-[53px] font-bold leading-[63px] text-[#369F48]">
                                        5
                                    </p>
                                    <p className="text-tr-m font-normal text-[#264354]">
                                        Навыков освоено
                                    </p>
                                </div>
                                <div className="flex items-center gap-x-[15px]">
                                    <p className="text-[53px] font-bold leading-[63px] text-[#566DA3]">
                                        7
                                    </p>
                                    <p className="w-[170px] text-tr-m font-normal text-[#264354]">
                                        Учебных материалов пройдено
                                    </p>
                                </div>
                            </div>
                            <img
                                src={weekProgressPic}
                                alt="картинка"
                                className="absolute bottom-0 right-0"
                            />
                        </div>
                    </div>
                    <div className="flex h-[235px] w-full flex-col gap-y-[25px] rounded-[20px] px-[20px] pt-[15px] shadow-[0_0_10px_0_rgba(38,67,84,0.1)]">
                        <p className="text-tr-m font-medium text-[#264354]">
                            Общая эффективность
                        </p>
                        <LineDiagram
                            width={"100%"}
                            height={"100%"}
                            graphData={backend_response}
                        />
                    </div>
                </div>

                <div className="flex h-[685px] w-[455px] flex-col gap-y-[20px]">
                    <Calendar />
                    <Events />
                </div>
            </div>

            {!openPopupTest ? (
                ""
            ) : (
                <PopupTest
                    session={null}
                    quiz={threat.quiz.id}
                    setOpenPopupTest={setOpenPopupTest}
                    typeQuiz="threat"
                />
            )}
        </section>
    );
}

export default MainPage;
