import { ReactComponent as GirlDontKnow } from "shared/ui/icon/GirlDontKnow.svg";
import { getEvents, useStore, useStoreDate } from "./model";
import { useQuery } from "@tanstack/react-query";
import { Content } from "shared/types";
import { Loader } from "components/Loader/Loader";
import { useState } from "react";
import { current } from "immer";
import { ReactComponent as ListClose } from "../../shared/ui/icon/ListClose.svg";
import { ReactComponent as ListOpen } from "../../shared/ui/icon/ListOpen.svg";
import { ReactComponent as EyeIcon } from "../../shared/ui/icon/EyeIcon.svg";

function transformDate(date: Date) {
    const months = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
    ];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day} ${months[monthIndex]} ${year} ${hours}:${minutes}`;
}

function EventItem({ content }: { content: Content }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const date = new Date(content.event_date);
    const dateString = transformDate(date);

    return (
        <>
            {/* <div
            data-isExpanded={isExpanded}
            className="group border-monochrome-light px-[20px] py-[10px] text-main-dark data-[isExpanded=true]:rounded-[6px] data-[isExpanded=false]:border-b data-[isExpanded=true]:shadow-darkBlue"
        >
            <div className="flex items-center">
                <div className="grow">
                    <p className="text-tr-m font-medium">{dateString}</p>
                    <p className="mt-[10px] text-tr-s font-medium">
                        {content.name}
                    </p>
                </div>
                <button
                    className="h-4 w-4"
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    {isExpanded ? "⬆" : "⬇"}
                </button>
            </div>
            {isExpanded && (
                <div className="mt-[10px] font-normal">
                    {content.url && (
                        <a className="underline" href={content.url}>
                            Ссылка на событие
                            <br />
                        </a>
                    )}
                    {content.description}
                </div>
            )}
        </div> */}

            {isExpanded === false ? (
                <div className="mt-[20px] flex w-full items-center justify-between border-b-[1px] border-b-monochrome-light  px-5 py-[10px]">
                    <div className="flex w-full flex-col gap-y-[10px]">
                        <div className="flex gap-x-[5px]">
                            <p className="text-tr-m font-medium text-[#264354]">
                                {dateString}
                            </p>
                            {/* <div className="flex w-full max-w-[355px] rounded bg-[#F37D73] bg-opacity-20 justify-center items-center"> 
                            <p className="text-tr-s text-[#F37D73] font-normal">приближается дедлайн по учебному материалу</p>
                        </div> */}
                        </div>
                        <p className="text-tr-s font-medium text-[#264354]">
                            {content.name}
                        </p>
                    </div>
                    <ListOpen
                        onClick={() => setIsExpanded(!isExpanded)}
                        className="cursor-pointer"
                    />
                </div>
            ) : (
                <div className="mt-[20px] flex flex-col gap-y-[10px]  rounded-md px-5 py-[10px] shadow-[0px_0px_15px_0px_#2643541A]">
                    <div className="flex w-full items-center justify-between">
                        <div className="flex w-full flex-col gap-y-[7px]">
                            <div className="flex gap-x-[5px]">
                                <p className="text-tr-m font-medium text-[#264354]">
                                    {dateString}
                                </p>
                                {/* <div className="flex w-full max-w-[355px] rounded bg-[#F37D73] bg-opacity-20 justify-center items-center"> 
                                <p className="text-tr-s text-[#F37D73] font-normal">приближается дедлайн по учебному материалу</p>
                            </div> */}
                            </div>
                            <p className="mt-[3px] text-tr-s font-medium text-[#264354]">
                                {content.name}
                            </p>
                        </div>
                        <ListClose
                            onClick={() => setIsExpanded(!isExpanded)}
                            className="cursor-pointer"
                        />
                    </div>

                    <div className="flex w-full items-center justify-between">
                        <div className="flex w-full flex-col gap-y-[7px]">
                            <p className="text-tr-s font-medium text-[#264354]">
                                {content.content_type.custom_name}
                            </p>
                            {/* <div className="flex gap-x-[5px]">
                            <p className="text-tr-m text-[#264354] font-medium" >Время прохождения</p>
                            <div className="flex w-full max-w-[70px] h-[20px] rounded bg-[#566DA333] bg-opacity-20 justify-center items-center"> 
                                <p className="text-tr-s text-[#264354] font-normal">15 минут</p>
                            </div>
                        </div> */}
                        </div>
                        {/* <div className="group hover:bg-[#264354] hover:text-white flex w-full max-w-[140px] h-full max-h-[35px] rounded-full items-center justify-between shadow-[0px_0px_15px_0px_#2643541A] cursor-pointer px-[20px]">
                        Изучить
                        <EyeIcon className="fill-[#264354] group-hover:fill-white"/>
                    </div> */}
                    </div>
                </div>
            )}
        </>
    );
}

function EventItem2({ content }: { content: Content }) {
    // const [isExpanded, setIsExpanded] = useState(false);
    // const date = new Date(content.event_date);
    // const dateString = transformDate(date);

    return (
        <div className="flex py-[10px]">
            <div className="flex flex-col items-center border-r-[1px] border-[#566DA3] px-[5px] py-[2px]">
                {/* {dateString} */}
                <p className="text-tr-xl font-medium text-[#264354]">20</p>
                <p className="text-tr-s font-normal text-[#264354]">Августа</p>
            </div>
            <div className="flex flex-col justify-between px-[10px] py-[2.5px] ">
                <div className="flex h-[22px] w-full rounded bg-[#F37D7333] px-[10px] py-[4px] text-tr-xs font-normal text-[#F37D73]">
                    приближается дедлайн по учебному материалу
                </div>
                <p className="text-tr-s font-medium text-[#264354]">
                    {content.name}
                </p>
            </div>
        </div>
    );
}

export function Events() {
    const selectedDay = useStore((s) => s.selectedDay);

    const { currentMonth, currentYear } = useStoreDate();
    const { data, status, error } = useQuery({
        queryKey: ["events-list", currentYear, currentMonth],
        queryFn: () => getEvents(currentYear, currentMonth + 1),
    });

    const dateString = new Date(
        currentYear,
        currentMonth,
        selectedDay
    ).toLocaleString("ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });

    const filteredEvents =
        data?.filter((event) => {
            const eventDate = new Date(event.event_date);
            return (
                currentMonth === eventDate.getMonth() &&
                selectedDay === eventDate.getDate()
            );
        }) || [];

    return (
        <div className="col-span-2 hidden max-h-[268px] w-full flex-col min-[800px]:flex">
            <div className="flex items-start justify-between">
                <div className="flex flex-col">
                    <p className="text-tr-m font-semibold text-[#264354] ">
                        Все события
                    </p>
                    {filteredEvents.length > 0 && (
                        <p className="mt-[15px] text-tr-m font-normal text-[#264354]">
                            {dateString}
                        </p>
                    )}
                </div>
                {filteredEvents.length > 0 && (
                    <div className="flex cursor-pointer items-center gap-x-[5px]">
                        <p className="text-tr-s font-normal text-[#264354]">
                            Смотреть все
                        </p>
                        <svg
                            width="16"
                            height="9"
                            viewBox="0 0 16 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M15.3536 4.85355C15.5488 4.65829 15.5488 4.34171 15.3536 4.14645L12.1716 0.964466C11.9763 0.769204 11.6597 0.769204 11.4645 0.964466C11.2692 1.15973 11.2692 1.47631 11.4645 1.67157L14.2929 4.5L11.4645 7.32843C11.2692 7.52369 11.2692 7.84027 11.4645 8.03553C11.6597 8.2308 11.9763 8.2308 12.1716 8.03553L15.3536 4.85355ZM0 5L15 5V4L0 4L0 5Z"
                                fill="#264354"
                            />
                        </svg>
                    </div>
                )}
            </div>

            {status === "pending" || status === "error" ? (
                <Loader text={"Загрузка..."} />
            ) : filteredEvents.length ? (
                <ul className="mb-[25px] space-y-[20px] overflow-y-hidden py-[10px]">
                    {filteredEvents.map((content) => (
                        <EventItem2 content={content} key={content.id} />
                    ))}
                </ul>
            ) : (
                <div className="flex items-center justify-center pt-[50px]">
                    <p className="text-tr-xxl font-bold text-[#26435499]">
                        Событий нет
                    </p>
                </div>
            )}
        </div>
    );
}
