import { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import { UserInfo } from "shared/types";

interface Props {
    data: UserInfo;
}

export const OrdersPieChartActives: React.FC<Props> = ({ data }) => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });
    }, [width]);

    const option: echarts.EChartsOption = {
        color: [
            new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                    offset: 0,
                    color: "#F3A773",
                },
                {
                    offset: 1,
                    color: "#F3A773",
                },
            ]),
            new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                    offset: 0,
                    color: "#F3A77380",
                },
                {
                    offset: 1,
                    color: "#F3A77380",
                },
            ]),
        ],
        series: [
            {
                name: "Item",
                type: "pie",
                radius: ["80%", "100%"],
                avoidLabelOverlap: false,
                label: {
                    position: "center",
                    formatter: function () {
                        return `{c|${Math.round(
                            (data.completed_actives_count /
                                data.actives_count) *
                                100
                        )}%} \n{a|${data.completed_actives_count}/${
                            data.actives_count
                        }}`;
                    },
                    rich: {
                        c: {
                            fontSize: width > 1010 ? 32 : 16,
                            fontWeight: 700,
                            color: "#264354CC",
                        },
                        a: {
                            fontSize: width > 1010 ? 20 : 12,
                            fontWeight: 500,
                            color: "#F3A773",
                        },
                    },
                },
                emphasis: {
                    scale: false,
                },
                data: [
                    {
                        value:
                            (data.completed_actives_count /
                                data.actives_count) *
                            100,
                        name: "10",
                    },
                    {
                        value:
                            100 -
                            (data.completed_actives_count /
                                data.actives_count) *
                                100,
                        name: "",
                    },
                ],
            },
        ],
    };

    return (
        <ReactECharts
            style={{ height: "100%", width: "100%" }}
            option={option}
        />
    );
};
