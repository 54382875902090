import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "components/Loader/Loader";
import * as types from "shared/types";
import * as http from "shared/http";
import { useQuery } from "@tanstack/react-query";
import useUser from "shared/api/useUser";
import imageProfile from "shared/ui/icon/imageProfile.svg";
import useUserWeekProgress from "shared/api/useUserWeekProgress";
import ProgressCircle from "shared/ui/ProgressCircleMainPage";
import { useStore } from "../../pages/trajectory/model";
import { queryClient } from "queryClient";

function DayProgress({ day, index }) {
    const daysOfWeek = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

    const dayStat = (
        day.completed_materials / day.opened_materials || 0
    ).toPrecision(1);

    const dayStatString = String(Number(dayStat) * 100);

    return (
        <div
            key={index}
            className="group flex cursor-pointer flex-col items-center justify-end gap-y-[3px]"
        >
            <div className="flex h-[18px] w-[35px] items-center justify-center rounded bg-[#F3A773] opacity-0 group-hover:opacity-100 ">
                <p className="text-tr-xs font-medium text-white">
                    {Number(dayStat) * 100}%
                </p>
            </div>
            <div
                className={`h-[${dayStatString}px] w-[35px] rounded bg-[#F3A773] group-hover:bg-[#F3A773]`}
            ></div>
            <p className="text-tr-s font-medium text-[#264354]">
                {daysOfWeek[index]}
            </p>
        </div>
    );
}

function WeekProgress({ weekData }) {
    // Define an array of day names for easier mapping

    const weekData2 = [
        {
            day: "2024-09-02",
            opened_materials: 2,
            completed_materials: 1,
        },
        {
            day: "2024-09-03",
            opened_materials: 3,
            completed_materials: 1,
        },
        {
            day: "2024-09-04",
            opened_materials: 4,
            completed_materials: 1,
        },
        {
            day: "2024-09-05",
            opened_materials: 1,
            completed_materials: 1,
        },
        {
            day: "2024-09-06",
            opened_materials: 5,
            completed_materials: 1,
        },
        {
            day: "2024-09-07",
            opened_materials: 8,
            completed_materials: 2,
        },
        {
            day: "2024-09-08",
            opened_materials: 6,
            completed_materials: 4,
        },
    ];

    return (
        <div className="mt-[10px] flex h-[150px] w-[700px] items-end justify-between">
            {weekData.map((day, index) => (
                <DayProgress day={day} index={index} />
            ))}
        </div>
    );
}

export const ProfilePage: React.FC = () => {
    const navigate = useNavigate();
    const setSphereId = useStore((s) => s.setSphereId);
    const { user, isLoading: isUserLoading, isError: isUserError } = useUser();
    const { weekProgress } = useUserWeekProgress();
    // const { spheres } = useSpheres();
    const daysOfWeek = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

    const sum =
        weekProgress?.reduce(
            (partialSum, week) => partialSum + week.opened_materials,
            0
        ) || 0;
    const {
        data: spheresList,
        error: spheresError,
        status: spheresStatus,
    } = useQuery({
        queryKey: ["spheres-list"],
        queryFn: async () => {
            const response = await http.request<
                types.Paginated<types.SphereType>
            >({
                url: `/api/v1/user/activity-fields/`,
            });
            return response.results;
        },
        refetchInterval: 60000,
    });

    // Старое
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });
    }, [width]);

    return (
        <main className="flex w-full flex-col overflow-auto">
            <section className="relative flex w-full justify-center">
                <div className="mb-[10px] mt-[30px] flex w-full max-w-[1415px] justify-between max-[834px]:mb-[60px]">
                    <div className="flex h-screen w-full flex-col">
                        <div className=" flex flex-col gap-[19px] max-[834px]:mb-[40px]">
                            <div className="mr-[10px] flex w-full max-w-[1415px] flex-col gap-[65px] max-[834px]:gap-[60px]">
                                <div className="mb-[20px] flex flex-col">
                                    <h2 className="text-[20px] font-bold leading-[24px] text-[#264354] max-[834px]:text-[16px] max-[834px]:leading-[19.2px]">
                                        Здравствуй, {user?.first_name}!
                                    </h2>
                                    <p className="mt-[20px] text-tr-m font-normal text-[#264354]">
                                        Выберите сферу деятельности что бы
                                        продолжить
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* {activesStatus === "success" ? (
                            <></>
                        ) : (
                            <div className="flex h-full w-full">
                                <Loader text="Загружаю" />
                            </div>
                        )} */}
                        <div className="flex w-full gap-x-[25px]">
                            <div className="flex h-[340px] w-[335px] flex-col items-center gap-y-[20px] rounded-lg p-5 shadow-[0px_0px_15px_0px_#2643541A]">
                                <div className="flex flex-col items-center gap-y-[10px]">
                                    <p className="text-tr-m font-medium text-[#264354]">
                                        {user?.first_name} {user?.surname}{" "}
                                        {user?.last_name}
                                    </p>
                                    <p className="text-tr-m font-normal text-[#264354]">
                                        {user?.position}
                                    </p>
                                    <img src={imageProfile} alt="lineBlue" />
                                </div>
                                <div className="flex flex-col items-center gap-y-[10px]">
                                    <p className="text-tr-m font-normal text-[#264354]">
                                        {user?.email}
                                    </p>
                                    <p className="text-tr-m font-medium text-[#264354]">
                                        Департамент/подразделение
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col justify-end gap-y-[15px]">
                                <div className="flex items-end gap-x-[15px]">
                                    <p className="text-tr-xxxxxl font-bold text-[#F3A773]">
                                        123
                                    </p>
                                    <p className="mb-[12px] text-tr-l font-bold text-[#264354]">
                                        Целей было достигнуто
                                    </p>
                                </div>
                                <div className="flex h-full max-h-[225px] w-full flex-col justify-between rounded-xl px-[20px] pb-[10px] pt-[20px] shadow-[0px_7px_22px_0px_#2643541A]">
                                    <div className="flex w-full justify-between">
                                        <p className="text-tr-s font-medium text-[#264354]">
                                            Средняя эффективность
                                        </p>
                                        {/* <div className="flex cursor-pointer items-center gap-x-[10px]">
                                        <p className="text-tr-s font-normal text-[#264354]">
                                            Неделя
                                        </p>
                                        <ListOpen className="mb-[2px]" />
                                    </div> */}
                                    </div>

                                    {sum > 0 ? (
                                        <WeekProgress weekData={weekProgress} />
                                    ) : (
                                        <div className="flex h-full w-[700px] flex-col items-center justify-between">
                                            <p className="mt-[55px] text-tr-s font-medium text-[#73C3F3]">
                                                Данных об эффективности нет
                                            </p>
                                            <div className="flex w-full items-end justify-between pl-[7px] pr-[8px]">
                                                {daysOfWeek.map(
                                                    (day, index) => (
                                                        <div
                                                            key={index}
                                                            className="flex cursor-pointer items-center"
                                                        >
                                                            <p className="text-tr-s font-medium text-[#264354]">
                                                                {
                                                                    daysOfWeek[
                                                                        index
                                                                    ]
                                                                }
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="flex h-[340px] w-[335px] flex-col items-center gap-y-[30px] rounded-lg p-5 shadow-[0px_0px_15px_0px_#2643541A]">
                                <div className="flex flex-col items-center gap-y-[10px]">
                                    <p className="text-tr-xxxl font-bold text-[#369F48]">
                                        156
                                    </p>
                                    <p className="text-tr-m font-normal text-[#264354]">
                                        Навыков освоено
                                    </p>
                                </div>
                                <div className="flex flex-col items-center gap-y-[10px]">
                                    <p className="text-tr-xxxl font-bold text-[#566DA3]">
                                        234
                                    </p>
                                    <p className="text-tr-m font-normal text-[#264354]">
                                        Учебных материалов пройдено
                                    </p>
                                </div>
                                <div className="flex flex-col items-center gap-y-[10px]">
                                    <p className="text-tr-xxxl font-bold text-[#264354]">
                                        45
                                    </p>
                                    <p className="w-[180px] text-center text-tr-m font-normal text-[#264354]">
                                        Академических часов проведено в обучении
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-[40px] flex flex-col">
                            <p className="text-tr-l font-medium text-[#264354]">
                                Сферы деятельности доступные для обучения
                            </p>
                            {spheresStatus === "success" ? (
                                <div className="mt-[40px] grid grid-cols-3 gap-[25px]">
                                    {spheresList.map((sphere) => (
                                        <>
                                            <div
                                                onClick={() => (
                                                    setSphereId(sphere.id),
                                                    queryClient.invalidateQueries(
                                                        {
                                                            queryKey: [
                                                                "mounth-progress",
                                                            ],
                                                        }
                                                    ),
                                                    navigate("/")
                                                )}
                                                className="mb-[20px] flex h-[300px] w-full max-w-[455px] cursor-pointer flex-col rounded-lg pl-[15px] shadow-[0px_0px_15px_0px_#2643541A]"
                                            >
                                                <div className="ml-auto flex h-[25px] w-full max-w-[185px] items-center justify-center rounded bg-[#F37D731A] text-tr-s font-normal text-[#F37D73]">
                                                    Были в прошлый раз
                                                </div>
                                                <div className="flex items-center gap-x-[10px]">
                                                    <svg
                                                        width="59"
                                                        height="56"
                                                        viewBox="0 0 59 56"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            cx="22.5"
                                                            cy="17.5"
                                                            r="8.5"
                                                            stroke="#264354"
                                                        />
                                                        <path
                                                            d="M39.9945 44.1111C40 43.7463 40 43.3756 40 43C40 37.4772 32.165 33 22.5 33C12.835 33 5 37.4772 5 43C5 48.5228 5 53 22.5 53C27.3803 53 30.8996 52.6518 33.4375 52.0298"
                                                            stroke="#264354"
                                                            stroke-linecap="round"
                                                        />
                                                        <path
                                                            d="M46.4986 9.78125C51.868 9.78125 56.2208 14.134 56.2208 19.5035C56.2208 21.2743 55.7474 22.9346 54.9202 24.3646M39.693 12.5604C37.8931 14.3249 36.7764 16.7838 36.7764 19.5035C36.7764 24.8729 41.1292 29.2257 46.4986 29.2257C48.2694 29.2257 49.9297 28.7523 51.3597 27.925"
                                                            stroke="#264354"
                                                            stroke-linecap="round"
                                                        />
                                                        <path
                                                            d="M39.6943 19.5009C39.6943 20.9468 40.1453 22.2874 40.9142 23.3898M46.4999 12.6953C50.2585 12.6953 53.3054 15.7423 53.3054 19.5009C53.3054 23.2595 50.2585 26.3064 46.4999 26.3064C45.4561 26.3064 44.4672 26.0714 43.5832 25.6515"
                                                            stroke="#264354"
                                                            stroke-linecap="round"
                                                        />
                                                        <path
                                                            d="M46.5 23.3872C48.6478 23.3872 50.3889 21.646 50.3889 19.4983C50.3889 17.3505 48.6478 15.6094 46.5 15.6094"
                                                            stroke="#264354"
                                                            stroke-linecap="round"
                                                        />
                                                    </svg>
                                                    <div className="flex flex-col gap-y-[10px]">
                                                        <p className="text-tr-m font-semibold text-[#264354]">
                                                            {sphere.name}
                                                        </p>
                                                        <p className="text-tr-m font-normal text-[#264354CC]">
                                                            {sphere.description}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="mt-[20px] flex gap-x-[10px]">
                                                    <p className="text-tr-s font-normal text-[#264354CC]">
                                                        Последнее посещение:
                                                    </p>
                                                    <p className="text-tr-s font-medium text-[#264354CC]">
                                                        12.12.2024
                                                    </p>
                                                </div>
                                                <div className="mt-[15px] flex w-full max-w-[430px] items-center">
                                                    <ProgressCircle
                                                        className="h-[130px] w-[130px]"
                                                        colorLine="#369F48"
                                                        ringType="short"
                                                        width={7}
                                                    />
                                                    <div className="ml-[30px] flex flex-col gap-y-[10px]">
                                                        <div className="flex gap-x-[5px]">
                                                            <p className="text-tr-m font-medium text-[#264354]">
                                                                123/179
                                                            </p>
                                                            <p className="text-tr-s font-normal text-[#264354CC]">
                                                                Целей достигнуто
                                                            </p>
                                                        </div>
                                                        <div className="flex gap-x-[5px]">
                                                            <p className="text-tr-m font-medium text-[#264354]">
                                                                143/199
                                                            </p>
                                                            <p className="text-tr-s font-normal text-[#264354CC]">
                                                                Навыков освоено
                                                            </p>
                                                        </div>
                                                        <div className="ml-auto flex items-center gap-x-[5px] text-tr-s font-medium text-[#264354]">
                                                            <p>Перейти</p>
                                                            <svg
                                                                width="16"
                                                                height="9"
                                                                viewBox="0 0 16 9"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M15.3536 4.85355C15.5488 4.65829 15.5488 4.34171 15.3536 4.14645L12.1716 0.964466C11.9763 0.769204 11.6597 0.769204 11.4645 0.964466C11.2692 1.15973 11.2692 1.47631 11.4645 1.67157L14.2929 4.5L11.4645 7.32843C11.2692 7.52369 11.2692 7.84027 11.4645 8.03553C11.6597 8.2308 11.9763 8.2308 12.1716 8.03553L15.3536 4.85355ZM0 5L15 5V4L0 4L0 5Z"
                                                                    fill="#264354"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            ) : (
                                <div className="flex h-full w-full pt-[250px]">
                                    <Loader text="Загружаю" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default ProfilePage;
