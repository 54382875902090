import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "components/Loader/Loader";
import * as types from "shared/types";
import * as http from "shared/http";
import { useStore } from "./model";
import { MaterialType } from "shared/types";
import { ActiveCard } from "./ActiveCard";
import { ThreatCard } from "./ThreatCard";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "queryClient";
import { useActiveThreats } from "shared/api/useActiveThreats";

export const Timeline: React.FC = () => {
    const navigate = useNavigate();
    const sphereId = useStore((s) => s.sphereId);
    if (sphereId === null) navigate("/profile");
    const [isLoadingContent, setIsLoadingContent] = useState(false);

    const [openThreats, setOpenThreats] = useState(false);
    const [openMaterials, setOpenMaterials] = useState(false);
    const [selectedTypeList, setSelectedTypeList] = useState("All");

    // const location = useLocation();
    // const goal = location.state.leftActive;

    const {
        data: activesList,
        error: activesError,
        status: activesStatus,
    } = useQuery({
        queryKey: ["user_trajectory_actives", "threats_count__gt=0", sphereId],
        queryFn: async () => {
            const response = await http.request<
                types.Paginated<types.TrajectoryActiveType>
            >({
                url: `/api/v1/user/trajectory_actives?page_size=200&activity_field=${sphereId}`,
            });
            return response.results;
        },
        enabled: sphereId !== null,
        refetchInterval: 60000,
    });

    const [selectedActiveId, setSelectedActiveId] = useState<
        number | undefined
    >(undefined);
    if (selectedActiveId === undefined && activesList?.length > 0) {
        setSelectedActiveId(activesList[0].id);
    }

    const selectedActive = activesList?.find((a) => a.id == selectedActiveId);

    // const {
    //     data: threatsData,
    //     error: threatsError,
    //     status: threatsStatus,
    // } = useQuery({
    //     queryKey: [
    //         "user_actives_threats",
    //         "has_content_or_quiz=true",
    //         selectedActiveId,
    //     ],
    //     queryFn: async () => {
    //         const response = await http.request<
    //             types.Paginated<types.ActiveThreatType>
    //         >({
    //             url: `/api/v1/user/actives_threats?trajectory_active=${selectedActiveId}`,
    //         });
    //         return response.results;
    //     },
    //     enabled: selectedActiveId != undefined,
    // });

    const filters = {
        trajectory_active: selectedActiveId,
    };

    if (selectedTypeList === "Fire") {
        filters["duration_percent__gte"] = 0.9;
        filters["duration_percent__lt"] = 1.0;
    } else if (selectedTypeList === "Loosed") {
        filters["duration_percent__gte"] = 1.0;
    } else if (selectedTypeList === "Oblivion") {
        filters["is_threat_resetted"] = true;
    }

    const queryFn = () =>
        http
            .request<types.Paginated<types.ActiveThreatType>>({
                method: "get",
                url: "/api/v1/user/actives_threats",
                params: filters,
            })
            .then((response) => response.results);

    const {
        data: threatsData,
        error: threatsError,
        status: threatsStatus,
    } = useActiveThreats(filters);

    const threatsList = threatsData ?? [];
    const [selectedThreatId, setSelectedThreatId] = useState<
        number | undefined
    >(undefined);
    const selectedThreat = threatsList?.at(selectedThreatId);

    const [editMaterial, setEditMaterial] = useState<MaterialType>();
    const [openedThreat, setOpenedThreat] = useState("");

    // const ModelApi3 = CreateModelApi(
    //     SkillsContentModel,
    //     `/api/v1/user/active_threat/${editThreat?.id}/content`,
    //     "",
    //     150
    // );

    const threatToCompletion: Record<
        number,
        { isAvailable: boolean; prev: number | null }
    > = {};

    for (const [i, active_threat] of threatsList.entries()) {
        if (i === 0) {
            threatToCompletion[active_threat.id] = {
                isAvailable: true,
                prev: null,
            };
        } else {
            const prevThreat = threatsList[i - 1];
            const nextElem = { isAvailable: false, prev: prevThreat.id };
            const prevAvailable =
                threatToCompletion[prevThreat.id].isAvailable ||
                prevThreat.content_count === 0;
            const prevThreatCompletion =
                prevThreat.content_count - prevThreat.completed_content_count;
            if (prevAvailable && prevThreatCompletion === 0) {
                nextElem.isAvailable = true;
            }

            threatToCompletion[active_threat.id] = nextElem;
        }
    }

    // Старое
    const [width, setWidth] = useState(window.innerWidth);

    const handleClickLinkGoal = (evt) => {
        evt.stopPropagation();
        navigate("/goals");
    };

    const handleClickLinkMain = (evt) => {
        evt.stopPropagation();
        navigate("/");
    };

    const handleClickLinkBack = (evt) => {
        evt.stopPropagation();
        navigate(-1);
    };

    const scrollLeft = () => {
        const ul = document.querySelector(".container-scroll");
        ul.scrollBy(-250, 0);
    };

    const scrollRight = () => {
        const ul = document.querySelector(".container-scroll");
        ul.scrollBy(250, 0);
    };

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });
        // if (isLoadingContent) {
        //     ModelApi3.loadFirstPage().then(() => {
        //         setIsLoadingContent(false);
        //     });
        // }
    }, [width, isLoadingContent]);

    return (
        <main className="flex w-full flex-col overflow-auto">
            <section className="relative flex w-full justify-center">
                <div className="mb-[10px] mt-[30px] flex w-full max-w-[1415px] justify-between max-[834px]:mb-[60px]">
                    <div className="flex h-screen w-full flex-col">
                        <div className="mb-[45px] flex flex-col gap-[19px] max-[834px]:mb-[40px]">
                            <p className="cursor-pointer text-[14px] font-normal leading-[16.8px] text-[#2643544D]">
                                <span onClick={handleClickLinkBack}>
                                    &#8592;
                                </span>{" "}
                                <span onClick={handleClickLinkMain}>
                                    Главная
                                </span>{" "}
                                <span onClick={handleClickLinkGoal}>/Цели</span>
                            </p>
                            <h2 className="text-[20px] font-bold leading-[24px] text-[#264354]">
                                Траектория обучения
                            </h2>
                        </div>
                        {activesStatus === "success" &&
                        threatsStatus === "success" ? (
                            <div className="flex w-full gap-x-[30px]">
                                <div className="flex flex-col gap-y-[40px]">
                                    <p className="text-tr-l font-medium text-main-dark">
                                        Все цели
                                    </p>
                                    <div className="flex flex-col">
                                        {activesList !== undefined && (
                                            <>
                                                {activesList?.map(
                                                    (active, index) => {
                                                        return (
                                                            <ActiveCard
                                                                active={active}
                                                                selectedActiveId={
                                                                    selectedActiveId
                                                                }
                                                                setSelectedActiveId={
                                                                    setSelectedActiveId
                                                                }
                                                                setOpenMaterials={
                                                                    setOpenMaterials
                                                                }
                                                                setOpenThreats={
                                                                    setOpenThreats
                                                                }
                                                                index={index}
                                                                activeListLenght={
                                                                    activesList.length
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="flex w-full flex-col gap-y-[20px]">
                                    <div className="flex flex-col gap-y-[10px]">
                                        <p className="flex gap-x-[5px] text-tr-l font-medium text-[#369F48]">
                                            Все навыки
                                        </p>
                                    </div>
                                    <div className="flex w-full border-b-[1px] border-[#26435499]">
                                        <div
                                            onClick={() => {
                                                setSelectedTypeList("All");
                                                queryClient.invalidateQueries({
                                                    queryKey: [
                                                        "user_actives_threats",
                                                    ],
                                                });
                                            }}
                                            className={`flex h-[45px] w-full max-w-[230px] cursor-pointer items-center justify-center ${
                                                selectedTypeList === "All" &&
                                                " border-b-[2px] border-[#369F48]"
                                            }`}
                                        >
                                            <svg
                                                width="19"
                                                height="26"
                                                viewBox="0 0 19 26"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M4.31171 16.5996L3.58385 19.3872C2.86575 22.1374 2.5067 23.5125 2.98583 24.2653C3.15375 24.5291 3.37901 24.7409 3.6404 24.8806C4.38626 25.2794 5.53787 24.6493 7.8411 23.3892C8.60745 22.9699 8.99062 22.7602 9.39778 22.7147C9.5487 22.6978 9.70075 22.6978 9.85166 22.7147C10.2588 22.7602 10.642 22.9699 11.4083 23.3892C13.7116 24.6493 14.8632 25.2794 15.609 24.8806C15.8704 24.7409 16.0957 24.5291 16.2636 24.2653C16.7427 23.5125 16.3837 22.1374 15.6656 19.3872L14.9377 16.5996"
                                                    stroke="#369F48"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                />
                                                <path
                                                    d="M2.19643 6.2763C1.82781 7.2419 1.625 8.29608 1.625 9.4C1.625 14.0392 5.20672 17.8 9.625 17.8C14.0433 17.8 17.625 14.0392 17.625 9.4C17.625 4.76081 14.0433 1 9.625 1C8.57365 1 7.56967 1.21295 6.65005 1.6"
                                                    stroke="#369F48"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                />
                                            </svg>
                                            <p className="ml-[10px] text-tr-m font-medium text-[#369F48]">
                                                Все навыки
                                            </p>
                                            <p className="ml-[5px] text-tr-l font-medium text-[#369F48]">
                                                {selectedActive?.threats_count |
                                                    0}
                                            </p>
                                        </div>
                                        <div
                                            onClick={() => {
                                                setSelectedTypeList("Loosed");
                                                queryClient.invalidateQueries({
                                                    queryKey: [
                                                        "user_actives_threats",
                                                    ],
                                                });
                                            }}
                                            className={`flex h-[45px] w-full max-w-[230px] cursor-pointer items-center justify-center ${
                                                selectedTypeList === "Loosed" &&
                                                " border-b-[2px] border-[#F37D73]"
                                            }`}
                                        >
                                            <svg
                                                width="34"
                                                height="44"
                                                viewBox="0 0 34 44"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="mt-[-5px]"
                                            >
                                                <g filter="url(#filter0_d_12073_169689)">
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M10.3997 39.6203C8.44603 36.7921 8.97016 32.3314 10.9445 28.881C11.0611 31.6664 11.6729 32.3505 12.9806 33.2127C12.1269 27.6228 12.5847 24.8815 16.5216 21.3005C15.9565 22.8372 16.354 26.1731 19.8855 28.6103C21.6627 29.7829 23.778 32.5323 23.6036 35.6493L23.5976 35.7573C23.518 37.1866 23.4319 38.734 22.6477 39.9729C30.61 36.3289 31.4388 26.3296 26.243 17.2113C26.0407 22.0656 24.9789 23.2579 22.7094 24.7606C24.1911 15.0186 23.3965 10.241 16.5641 4C17.5448 6.67818 16.855 12.492 10.726 16.7394C7.6418 18.7831 3.97071 23.5747 4.27341 29.0071C4.54045 33.7993 6.53103 37.3926 10.3997 39.6203Z"
                                                        fill="url(#paint0_linear_12073_169689)"
                                                    />
                                                </g>
                                                <rect
                                                    x="4.875"
                                                    y="19"
                                                    width="24"
                                                    height="24"
                                                    rx="12"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M16.875 27V31L19.375 33.5"
                                                    stroke="#F37D73"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M11.875 22.3378C13.3459 21.487 15.0536 21 16.875 21C22.3978 21 26.875 25.4772 26.875 31C26.875 36.5228 22.3978 41 16.875 41C11.3522 41 6.875 36.5228 6.875 31C6.875 29.1786 7.36197 27.4709 8.21282 26"
                                                    stroke="#F37D73"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                />
                                                <defs>
                                                    <filter
                                                        id="filter0_d_12073_169689"
                                                        x="-3"
                                                        y="0"
                                                        width="41"
                                                        height="45"
                                                        filterUnits="userSpaceOnUse"
                                                        color-interpolation-filters="sRGB"
                                                    >
                                                        <feFlood
                                                            flood-opacity="0"
                                                            result="BackgroundImageFix"
                                                        />
                                                        <feColorMatrix
                                                            in="SourceAlpha"
                                                            type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                            result="hardAlpha"
                                                        />
                                                        <feOffset />
                                                        <feGaussianBlur stdDeviation="2" />
                                                        <feComposite
                                                            in2="hardAlpha"
                                                            operator="out"
                                                        />
                                                        <feColorMatrix
                                                            type="matrix"
                                                            values="0 0 0 0 1 0 0 0 0 0.305882 0 0 0 0 0.14902 0 0 0 0.25 0"
                                                        />
                                                        <feBlend
                                                            mode="normal"
                                                            in2="BackgroundImageFix"
                                                            result="effect1_dropShadow_12073_169689"
                                                        />
                                                        <feBlend
                                                            mode="normal"
                                                            in="SourceGraphic"
                                                            in2="effect1_dropShadow_12073_169689"
                                                            result="shape"
                                                        />
                                                    </filter>
                                                    <linearGradient
                                                        id="paint0_linear_12073_169689"
                                                        x1="16.8878"
                                                        y1="4"
                                                        x2="16.8878"
                                                        y2="39.9729"
                                                        gradientUnits="userSpaceOnUse"
                                                    >
                                                        <stop stop-color="#FF4E26" />
                                                        <stop
                                                            offset="1"
                                                            stop-color="#FFBEAF"
                                                        />
                                                    </linearGradient>
                                                </defs>
                                            </svg>

                                            <p className="ml-[10px] text-tr-m font-medium text-[#F37D73]">
                                                Просроченные
                                            </p>
                                            <p className="ml-[5px] text-tr-l font-medium text-[#F37D73]">
                                                {selectedActive?.expired_threats |
                                                    0}
                                            </p>
                                        </div>
                                        <div
                                            onClick={() => {
                                                setSelectedTypeList("Fire");
                                                queryClient.invalidateQueries({
                                                    queryKey: [
                                                        "user_actives_threats",
                                                    ],
                                                });
                                            }}
                                            className={`flex h-[45px] w-full max-w-[230px] cursor-pointer items-center justify-center ${
                                                selectedTypeList === "Fire" &&
                                                " border-b-[2px] border-[#264354]"
                                            }`}
                                        >
                                            <svg
                                                width="23"
                                                height="28"
                                                viewBox="0 0 23 28"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g filter="url(#filter0_d_12073_169698)">
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M8.25156 23.2542C7.18593 21.7255 7.47183 19.3143 8.54875 17.4492C8.61234 18.9548 8.94607 19.3246 9.65934 19.7907C9.19366 16.7691 9.44341 15.2873 11.5908 13.3516C11.2826 14.1823 11.4994 15.9855 13.4257 17.3029C14.395 17.9367 15.5488 19.4229 15.4537 21.1078L15.4504 21.1661C15.407 21.9387 15.3601 22.7751 14.9323 23.4448C19.2754 21.4751 19.7274 16.07 16.8934 11.1412C16.783 13.7652 16.2039 14.4096 14.966 15.2219C15.7742 9.95598 15.3407 7.37353 11.614 4C12.1489 5.44766 11.7727 8.59029 8.42957 10.8862C6.74727 11.9909 4.74485 14.5809 4.90997 17.5173C5.05562 20.1077 6.14139 22.0501 8.25156 23.2542Z"
                                                        fill="url(#paint0_linear_12073_169698)"
                                                    />
                                                </g>
                                                <defs>
                                                    <filter
                                                        id="filter0_d_12073_169698"
                                                        x="-0.875"
                                                        y="0"
                                                        width="26"
                                                        height="28"
                                                        filterUnits="userSpaceOnUse"
                                                        color-interpolation-filters="sRGB"
                                                    >
                                                        <feFlood
                                                            flood-opacity="0"
                                                            result="BackgroundImageFix"
                                                        />
                                                        <feColorMatrix
                                                            in="SourceAlpha"
                                                            type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                            result="hardAlpha"
                                                        />
                                                        <feOffset />
                                                        <feGaussianBlur stdDeviation="2" />
                                                        <feComposite
                                                            in2="hardAlpha"
                                                            operator="out"
                                                        />
                                                        <feColorMatrix
                                                            type="matrix"
                                                            values="0 0 0 0 1 0 0 0 0 0.305882 0 0 0 0 0.14902 0 0 0 0.25 0"
                                                        />
                                                        <feBlend
                                                            mode="normal"
                                                            in2="BackgroundImageFix"
                                                            result="effect1_dropShadow_12073_169698"
                                                        />
                                                        <feBlend
                                                            mode="normal"
                                                            in="SourceGraphic"
                                                            in2="effect1_dropShadow_12073_169698"
                                                            result="shape"
                                                        />
                                                    </filter>
                                                    <linearGradient
                                                        id="paint0_linear_12073_169698"
                                                        x1="11.7905"
                                                        y1="4"
                                                        x2="11.7905"
                                                        y2="23.4448"
                                                        gradientUnits="userSpaceOnUse"
                                                    >
                                                        <stop stop-color="#FF4E26" />
                                                        <stop
                                                            offset="1"
                                                            stop-color="#FFBEAF"
                                                        />
                                                    </linearGradient>
                                                </defs>
                                            </svg>

                                            <p className="ml-[10px] text-tr-m font-medium text-[#26435499]">
                                                Горячие
                                            </p>
                                            <p className="ml-[5px] text-tr-l font-medium text-[#26435499]">
                                                {selectedActive?.hot_threats |
                                                    0}
                                            </p>
                                        </div>
                                        <div
                                            onClick={() =>
                                                setSelectedTypeList("Oblivion")
                                            }
                                            className={`flex h-[45px] w-full max-w-[230px] cursor-pointer items-center justify-center ${
                                                selectedTypeList ===
                                                    "Oblivion" &&
                                                " border-b-[2px] border-[#264354]"
                                            }`}
                                        >
                                            <svg
                                                width="26"
                                                height="24"
                                                viewBox="0 0 26 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    x="0.375"
                                                    width="24"
                                                    height="24"
                                                    rx="12"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M12.375 8V12L14.875 14.5"
                                                    stroke="#369F48"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M22.375 12C22.375 17.5228 17.8978 22 12.375 22C6.85215 22 2.375 17.5228 2.375 12C2.375 6.47715 6.85215 2 12.375 2C14.1964 2 15.9041 2.48697 17.375 3.33782M22.375 12L19.5 14.5M22.375 12L24.5 15"
                                                    stroke="#369F48"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                />
                                            </svg>

                                            <p className="ml-[10px] text-tr-m font-medium text-[#26435499]">
                                                Утраченные
                                            </p>
                                            <p className="ml-[5px] text-tr-l font-medium text-[#26435499]">
                                                {selectedActive?.resetted_threats |
                                                    0}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mb-[20px] flex w-full flex-col gap-y-[20px]">
                                        {threatsList !== undefined && (
                                            <>
                                                {threatsList?.map(
                                                    (skill, index) => {
                                                        return (
                                                            <ThreatCard
                                                                threat={skill}
                                                                setEditMaterial={
                                                                    setEditMaterial
                                                                }
                                                                setSelectedThreatId={
                                                                    setSelectedThreatId
                                                                }
                                                                setOpenedThreat={
                                                                    setOpenedThreat
                                                                }
                                                                selectedThreatId={
                                                                    selectedThreatId
                                                                }
                                                                openedThreat={
                                                                    openedThreat
                                                                }
                                                                index={index}
                                                                activeOrdered={
                                                                    selectedActive?.is_ordered
                                                                }
                                                                threatListLength={
                                                                    threatsList.length
                                                                }
                                                                available={
                                                                    threatToCompletion[
                                                                        skill.id
                                                                    ]
                                                                        .isAvailable
                                                                }
                                                                selectedTypeList={
                                                                    selectedTypeList
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="flex h-full w-full">
                                <Loader text="Загружаю" />
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Timeline;
