import * as http from "shared/http";
import { ActiveThreatType, Paginated } from "shared/types";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "../../pages/trajectory/model";

export function getAllThreatsList(sphereId: number) {
    return http.request<Paginated<ActiveThreatType>>({
        method: "get",
        url: `/api/v1/user/actives_threats?activity_field=${sphereId}`, 
    });
}

export default function useAllThreatsList() {
    const sphereId = useStore((s) => s.sphereId);
    const { data, status, error } = useQuery({
        queryKey: ["allThreats-list", sphereId],
        queryFn: () => getAllThreatsList(sphereId),
        enabled: sphereId !== null,
    });
    return {
        allThreatsList: data,
        isLoadingAllThreats: status === "pending",
        isErrorAllThreats: status === "error",
    };
}
