import { create } from "zustand";

type State = {
    sphereId: number | null;
};

type Action = {
    setSphereId: (sphereId: number | null) => void;
};

export const useStore = create<State & Action>((set) => ({
    sphereId: null,
    setSphereId: (sphereId) => set({ sphereId }),
}));
