import React from "react";
import { useNavigate } from "react-router-dom";
// import "./NavbarMenu.css";
// import ProgressCircle from "shared/ui/ProgressCircleX3";
import { useQuery } from "@tanstack/react-query";
import * as http from "shared/http";
import * as types from "shared/types";
import { Loader } from "components/Loader/Loader";
import ProgressCircleMainPage from "shared/ui/ProgressCircleMainPage";
import { useStore } from "../../pages/trajectory/model";
import { queryClient } from "queryClient";

interface MenuItemType {
    link: string;
    text: string;
    logo: React.ReactElement;
}

interface NavbarMenuProps {
    openMenu: boolean;
    toggleMenu: () => void;
}

const MenuItem: MenuItemType[] = [
    {
        link: "",
        text: "Цели",
        logo: <div className="yourImageTarget"></div>,
    },
    {
        link: "materials",
        text: "Учебные материалы",
        logo: <div className="yourImageMat"></div>,
    },
];

export const NavbarMenu: React.FC<NavbarMenuProps> = ({
    openMenu,
    toggleMenu,
}) => {
    const sphereId = useStore((s) => s.sphereId);
    const setSphereId = useStore((s) => s.setSphereId);
    const navigate = useNavigate();
    const {
        data: spheresList,
        error: spheresError,
        status: spheresStatus,
    } = useQuery({
        queryKey: ["spheres-list"],
        queryFn: async () => {
            const response = await http.request<
                types.Paginated<types.SphereType>
            >({
                url: `/api/v1/user/activity-fields/`,
            });
            return response.results;
        },
        refetchInterval: 60000,
    });

    const clickMenuItem = (link: string) => {
        navigate(`/${link}`);
        toggleMenu();
    };

    if (!openMenu) {
        return <></>;
    }

    return (
        <div className="absolute left-0 top-0 z-50 h-full w-[710px] bg-white p-8 pb-[70px] text-black shadow-[0px_0px_15px_0px_#2643541A]">
            <div className="flex w-full items-center justify-between">
                <p className="text-tr-s font-medium text-main-dark ">
                    Сферы деятельности
                </p>
                <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer"
                    onClick={toggleMenu}
                >
                    <path
                        d="M21 1L1 21M21 21L6 6M3.5 3.5L1 1"
                        stroke="#F37D73"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                    <path
                        d="M3.5 3.5L1 1"
                        stroke="#F37D73"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                </svg>
            </div>
            <div className="mt-[40px] flex h-full flex-col gap-y-[25px] overflow-y-auto p-[10px]">
                {spheresStatus === "success" ? (
                    <>
                        {spheresList.map((sphere) => (
                            <>
                                <div
                                    onClick={() => (
                                        setSphereId(sphere.id),
                                        toggleMenu(),
                                        queryClient.invalidateQueries({
                                            queryKey: ["mounth-progress"],
                                        })
                                    )}
                                    className={`mb-[20px] flex h-[300px] w-full cursor-pointer flex-col rounded-lg pl-[15px] shadow-[0px_0px_15px_0px_#2643541A] ${
                                        sphereId === sphere.id &&
                                        " border border-main-red"
                                    }`}
                                >
                                    <div className="ml-auto flex h-[25px] w-full max-w-[185px] items-center justify-center rounded bg-[#F37D731A] text-tr-s font-normal text-[#F37D73]">
                                        Были в прошлый раз
                                    </div>
                                    <div className="flex items-center gap-x-[10px]">
                                        <svg
                                            width="59"
                                            height="56"
                                            viewBox="0 0 59 56"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="22.5"
                                                cy="17.5"
                                                r="8.5"
                                                stroke="#264354"
                                            />
                                            <path
                                                d="M39.9945 44.1111C40 43.7463 40 43.3756 40 43C40 37.4772 32.165 33 22.5 33C12.835 33 5 37.4772 5 43C5 48.5228 5 53 22.5 53C27.3803 53 30.8996 52.6518 33.4375 52.0298"
                                                stroke="#264354"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M46.4986 9.78125C51.868 9.78125 56.2208 14.134 56.2208 19.5035C56.2208 21.2743 55.7474 22.9346 54.9202 24.3646M39.693 12.5604C37.8931 14.3249 36.7764 16.7838 36.7764 19.5035C36.7764 24.8729 41.1292 29.2257 46.4986 29.2257C48.2694 29.2257 49.9297 28.7523 51.3597 27.925"
                                                stroke="#264354"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M39.6943 19.5009C39.6943 20.9468 40.1453 22.2874 40.9142 23.3898M46.4999 12.6953C50.2585 12.6953 53.3054 15.7423 53.3054 19.5009C53.3054 23.2595 50.2585 26.3064 46.4999 26.3064C45.4561 26.3064 44.4672 26.0714 43.5832 25.6515"
                                                stroke="#264354"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M46.5 23.3872C48.6478 23.3872 50.3889 21.646 50.3889 19.4983C50.3889 17.3505 48.6478 15.6094 46.5 15.6094"
                                                stroke="#264354"
                                                stroke-linecap="round"
                                            />
                                        </svg>
                                        <div className="flex flex-col gap-y-[10px]">
                                            <p className="text-tr-m font-semibold text-[#264354]">
                                                {sphere.name}
                                            </p>
                                            <p className="text-tr-m font-normal text-[#264354CC]">
                                                {sphere.description}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-[20px] flex gap-x-[10px]">
                                        <p className="text-tr-s font-normal text-[#264354CC]">
                                            Последнее посещение:
                                        </p>
                                        <p className="text-tr-s font-medium text-[#264354CC]">
                                            12.12.2024
                                        </p>
                                    </div>
                                    <div className="mt-[15px] flex w-full items-center justify-between pr-[15px]">
                                        <ProgressCircleMainPage
                                            className="h-[130px] w-[130px]"
                                            colorLine="#369F48"
                                            ringType="short"
                                            width={7}
                                        />
                                        <div className="ml-[30px] flex flex-col gap-y-[10px]">
                                            <div className="flex gap-x-[5px]">
                                                <p className="text-tr-m font-medium text-[#264354]">
                                                    123/179
                                                </p>
                                                <p className="text-tr-s font-normal text-[#264354CC]">
                                                    Целей достигнуто
                                                </p>
                                            </div>
                                            <div className="flex gap-x-[5px]">
                                                <p className="text-tr-m font-medium text-[#264354]">
                                                    143/199
                                                </p>
                                                <p className="text-tr-s font-normal text-[#264354CC]">
                                                    Навыков освоено
                                                </p>
                                            </div>
                                            <div className="ml-auto flex items-center gap-x-[5px] text-tr-s font-medium text-[#264354]">
                                                <p>Перейти</p>
                                                <svg
                                                    width="16"
                                                    height="9"
                                                    viewBox="0 0 16 9"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M15.3536 4.85355C15.5488 4.65829 15.5488 4.34171 15.3536 4.14645L12.1716 0.964466C11.9763 0.769204 11.6597 0.769204 11.4645 0.964466C11.2692 1.15973 11.2692 1.47631 11.4645 1.67157L14.2929 4.5L11.4645 7.32843C11.2692 7.52369 11.2692 7.84027 11.4645 8.03553C11.6597 8.2308 11.9763 8.2308 12.1716 8.03553L15.3536 4.85355ZM0 5L15 5V4L0 4L0 5Z"
                                                        fill="#264354"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                    </>
                ) : (
                    <div className="flex h-full w-full">
                        <Loader text="Загружаю" />
                    </div>
                )}
            </div>
        </div>
    );
};
