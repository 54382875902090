import { FC, PureComponent } from "react";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";

type BackElem = {
    day: number;
    opened_materials: number;
    completed_materials: number;
};

interface Props {
    width: string;
    height: string;
    graphData: BackElem[] | undefined;
}

interface Props {
    width: string;
    height: string;
}

// const backend_response: BackElem[] = [
//     { day: 5, opened_materials: 9, completed_materials: 6 },
//     { day: 6, opened_materials: 11, completed_materials: 8 },
//     { day: 7, opened_materials: 4, completed_materials: 3 },
//     { day: 8, opened_materials: 15, completed_materials: 12 },
//     { day: 9, opened_materials: 6, completed_materials: 5 },
//     { day: 10, opened_materials: 17, completed_materials: 14 },
//     { day: 11, opened_materials: 8, completed_materials: 7 },
//     { day: 12, opened_materials: 3, completed_materials: 2 },
//     { day: 13, opened_materials: 500, completed_materials: 15 },
//     { day: 14, opened_materials: 18, completed_materials: 16 },
//     { day: 15, opened_materials: 7, completed_materials: 6 },
//     { day: 16, opened_materials: 30, completed_materials: 25 },
//     { day: 25, opened_materials: 19, completed_materials: 18 },
//     { day: 26, opened_materials: 37, completed_materials: 34 },
//     { day: 27, opened_materials: 28, completed_materials: 27 },
//     { day: 28, opened_materials: 56, completed_materials: 53 },
//     { day: 29, opened_materials: 49, completed_materials: 48 },
//     { day: 30, opened_materials: 21, completed_materials: 20 },
// ];

function YAxisTick({ x, y, payload }: { x: number; y: number; payload: any }) {
    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={-10}
                y={8}
                dy={16}
                textAnchor="end"
                fill="#666"
                transform="translate(0 -20)"
                className="text-tr-xs font-normal text-main-dark"
            >
                {payload.value}%
            </text>
        </g>
    );
}

export const LineDiagram: FC<Props> = ({ width, height, graphData }) => {
    const TICK_MONTH = "августа";

    const months = [
        "Января",
        "Февраля",
        "Марта",
        "Апреля",
        "Мая",
        "Июня",
        "Июля",
        "Августа",
        "Сентября",
        "Октября",
        "Ноября",
        "Декабря",
    ];

    const date = new Date();
    const month = date.getMonth();
    const monthName = months[month];

    type RangeElem = {
        name: string; // название диапазона
        прогресс: number; // 0 - 1.0
        opened_materials: number;
        completed_materials: number;
        range_start: number;
        range_end: number;
    };

    function getDaysInCurrentMonth() {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    }

    function XAxisTick({
        x,
        y,
        payload,
    }: {
        x: number;
        y: number;
        payload: any;
    }) {
        return (
            <g transform={`translate(${x},${y})`}>
                <text
                    x={0}
                    y={-10}
                    dy={16}
                    textAnchor="middle"
                    fill="#666"
                    transform="translate(0 -20)"
                    className="text-tr-xs font-normal text-main-dark"
                >
                    {payload.value}
                </text>
                {/* <text
                    x={0}
                    y={30}
                    dy={16}
                    textAnchor="middle"
                    fill="#666"
                    transform="translate(0 -20)"
                    className="font-normal text-tr-xs text-main-dark"
                >
                    {monthName}
                </text> */}
            </g>
        );
    }

    // делим месяц на отрезки длиной range_length
    const RANGE_LENGTH = 7;
    const monthDays = getDaysInCurrentMonth();
    const plot_data: RangeElem[] = [];

    // заполняем диапазоны
    for (let i = 0; i < monthDays; i += RANGE_LENGTH) {
        const range_start = i + 1;
        const range_end = Math.min(range_start + RANGE_LENGTH - 1, monthDays);

        plot_data.push({
            name: `${range_start}-${range_end}`,
            completed_materials: 0,
            opened_materials: 0,
            range_start,
            range_end,
            прогресс: 0,
        });
    }

    // if (backend_response === undefined) {
    //     backend_response = [];
    // }

    // суммирование значений
    for (const elem of graphData) {
        const range_elem = plot_data.find(
            (pd) => pd.range_start <= elem.day && elem.day <= pd.range_end
        );
        if (range_elem != null) {
            range_elem.opened_materials += elem.opened_materials;
            range_elem.completed_materials += elem.completed_materials;
        }
    }

    // расчет процента
    for (const elem of plot_data) {
        if (elem.opened_materials > 0 && elem.completed_materials > 0)
            elem["прогресс"] = Math.round(
                (elem.completed_materials / elem.opened_materials) * 100
            );
    }

    return (
        <ResponsiveContainer width={width} height={height}>
            <AreaChart
                data={plot_data}
                margin={{
                    top: 0,
                    right: 20,
                    left: -10,
                    bottom: 20,
                }}
            >
                <defs>
                    <linearGradient id="mainColor" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stop-color="#80F373 " />
                        <stop offset="80%" stop-color="#FFFFFF" />
                    </linearGradient>
                    <linearGradient id="lineColor" x1="0" y1="1" x2="0" y2="0">
                        <stop offset="0%" stop-color="#EF4A4A" />
                        <stop offset="50%" stop-color="#DDDA2E" />
                        <stop offset="100%" stop-color="#369F48" />
                    </linearGradient>
                </defs>
                <CartesianGrid vertical={false} />
                <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    tick={XAxisTick}
                    orientation="top"
                />
                <YAxis
                    axisLine={false}
                    tickLine={false}
                    ticks={[50, 100]}
                    tick={YAxisTick}
                />
                <Tooltip />
                <Area
                    type="monotone"
                    dataKey="прогресс"
                    stroke="url(#lineColor)"
                    fill="url(#mainColor)"
                />
            </AreaChart>
        </ResponsiveContainer>
    );
};
