import * as http from "shared/http";
import * as types from "shared/types";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "../../pages/trajectory/model";

export function GetUserMounthProgress(sphereId: number) {
    return http.request<types.MounthProgress[]>({
        method: "get",
        url: `/api/v1/user/content_sessions/?no_page&activity_field=${sphereId}&time_update__range=2025-01-01,2025-01-30`,
    });
}

export default function useUserMounthProgress() {
    const sphereId = useStore((s) => s.sphereId);
    const { data, status, error } = useQuery({
        queryKey: ["mounth-progress", sphereId],
        queryFn: () => GetUserMounthProgress(sphereId),
        enabled: sphereId !== null,
    });

    return {
        mounthProgress: data,
        isLoading: status === "pending",
        isError: status === "error",
    };
}
