import { truncateText } from "utils/truncateText";
import { useEffect } from "react";
import { useState } from "react";
import { useStore } from "effector-react";
import {
    CreateModelApi,
    CreatePaginatedModel,
} from "shared/paginatedModelBuilder";
import * as types from "shared/types";
import { LoaderConstructor } from "./LoaderConstructor/LoaderConstructor";
import { MaterialCard } from "./MaterialCard";
import ProgressCircle from "shared/ui/ProgressCircle";
import { useSessionPopup } from "components/MaterialSessionPopup/model";
import { ReactComponent as TestArrow } from "../../shared/ui/icon/TestArrow.svg";
import {
    ArrowComponent,
    ArrowDependence,
    ArrowDependenceEnd,
} from "./ArrowComponent/ArrowComponent";
import { useQuery } from "@tanstack/react-query";
import useThreatTest from "shared/api/useThreatTest";
import { PopupTest } from "components/PopupTest/PopupTest";
import * as http from "shared/http";
import { ReactComponent as EmptyThreat } from "../../shared/ui/icon/emptyThreat.svg";
import EmptyThreat2 from "../../shared/image/emptyThreat2.png";

function TestCard({
    editThreat,
    isQuizAvailable,
    setOpenPopupTest,
}: {
    editThreat: types.ActiveThreatType | undefined;
    isQuizAvailable: boolean;
    setOpenPopupTest: (boolean) => void;
}) {
    // const { quiz } = useThreatTest(String(editThreat?.quiz));

    function checkOpen(e: React.SyntheticEvent) {
        e.stopPropagation();
        if (isQuizAvailable === true) {
            setOpenPopupTest(true);
        }
    }

    // if (quiz === undefined) return <LoaderConstructor />;
    return (
        <div className="mt-[20px] flex h-[185px] w-full flex-col rounded-md bg-[#566DA3] px-[30px] py-[20px]">
            <p className="text-tr-m font-bold text-white">Контроль навыка</p>
            <p className="mt-[15px] text-tr-m font-normal text-white">
                {truncateText(editThreat.quiz.name, 10)}
            </p>

            <button
                onClick={checkOpen}
                className={` ml-auto mt-auto flex h-[35px] w-[170px] items-center justify-center gap-x-[5px] rounded-full bg-white text-tr-s font-medium text-[#566DA3] ${
                    isQuizAvailable ? " opacity-100" : " opacity-40"
                } `}
            >
                Пройти тест
                <svg
                    width="21"
                    height="9"
                    viewBox="0 0 21 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1 4C0.723858 4 0.5 4.22386 0.5 4.5C0.5 4.77614 0.723858 5 1 5V4ZM20.3536 4.85355C20.5488 4.65829 20.5488 4.34171 20.3536 4.14645L17.1716 0.964466C16.9763 0.769204 16.6597 0.769204 16.4645 0.964466C16.2692 1.15973 16.2692 1.47631 16.4645 1.67157L19.2929 4.5L16.4645 7.32843C16.2692 7.52369 16.2692 7.84027 16.4645 8.03553C16.6597 8.2308 16.9763 8.2308 17.1716 8.03553L20.3536 4.85355ZM1 5H20V4H1V5Z"
                        fill="#566DA3"
                    />
                </svg>
            </button>
        </div>
    );
}

interface Props {
    threat: types.ActiveThreatType | undefined;
    setSelectedThreatId: React.Dispatch<React.SetStateAction<number>>;
    selectedThreatId: number;
    setOpenedThreat: React.Dispatch<React.SetStateAction<string>>;
    openedThreat: string;
    setEditMaterial: React.Dispatch<
        React.SetStateAction<types.MaterialType | undefined>
    >;
    activeOrdered: boolean;
    index: number;
    threatListLength: number;
    available: boolean;
    selectedTypeList: string;
}

const SkillsContentModel =
    CreatePaginatedModel<types.MaterialType>("SkillsContent");

export const ThreatCard: React.FC<Props> = ({
    threat,
    setSelectedThreatId,
    selectedThreatId,
    setOpenedThreat,
    openedThreat,
    setEditMaterial,
    activeOrdered,
    index,
    threatListLength,
    available,
    selectedTypeList,
}) => {
    // const content = useStore(SkillsContentModel.store); // Получение модели умов навыка
    // const contentList = content.results; // Получение результатов модели
    const [isLoadingContent, setIsLoadingContent] = useState(false);
    const [openMaterials, setOpenMaterials] = useState(false);
    const visible = threat.reset_time !== null;
    const [openPopupTest, setOpenPopupTest] = useState(false);

    if (activeOrdered === false) {
        available = true;
    }

    const materialToCompletion: Record<
        number,
        { isAvailable: boolean; prev: number | null }
    > = {};

    let isQuizAvailable = threat.is_external_quiz;

    function openThreat() {
        if (threat.content_count > 0)
            if (available === true) {
                if (openMaterials === false) {
                    setSelectedThreatId(threat.id);
                    setOpenMaterials(true);
                    // setIsLoadingContent(false);
                    if (threat !== undefined)
                        setOpenedThreat(threat.threat.name);
                } else {
                    setSelectedThreatId(threat.id);
                    setOpenMaterials(false);
                    // setIsLoadingContent(false);
                }
            }
    }

    const openActiveMaterial = useSessionPopup((state) => state.open);

    const handleClickLinkThreat = (threatId: number) => {
        if (materialsList?.length === 0 && threat.quiz !== null) {
            setOpenPopupTest(true);
            // console.log("lf");
        } else {
            if (available === true) {
                if (typeof threatId !== "number") {
                    throw new Error("invalid threatId");
                }
                openActiveMaterial(null, threatId, null);
            }
        }
    };

    function closeThreat() {
        setSelectedThreatId(threat.id);
        setOpenMaterials(false);
        setIsLoadingContent(false);
    }

    const {
        data: materialsData,
        error: materialsError,
        status: materialsStatus,
    } = useQuery({
        queryKey: ["user_materials", selectedThreatId],
        queryFn: async () => {
            const response = await http.request<
                types.Paginated<types.MaterialType>
            >({
                url: `/api/v1/user/active_threat/${selectedThreatId}/content`,
            });
            return response.results;
        },
        enabled: selectedThreatId !== undefined,
    });
    const materialsList = materialsData ?? [];
    // const [selectedMaterialId, setSelectedMaterialId] = useState<
    //     number | undefined
    // >(undefined);
    // const selectedMaterial = materialsList?.at(selectedMaterialId);

    // useQuery({
    //     queryKey: ["materials-list"],
    //     queryFn: () => {
    //         if (editThreat !== undefined) {
    //             ModelApi3.loadFirstPage();
    //         }
    //     },
    // });

    const [typeOfYear, setTypeOfYear] = useState("");
    const [numberTypeOfYear, setNumberTypeOfYear] = useState(0);

    const fullResetTime = threat?.reset_time;
    const timer = " 00:00:00";
    const days = Number(fullResetTime?.replace(timer, ""));

    for (const [i, material] of materialsList.entries()) {
        if (i === 0) {
            materialToCompletion[material.id] = {
                isAvailable: true,
                prev: null,
            };
        } else {
            const prevMat = materialsList[i - 1];
            const nextElem = { isAvailable: false, prev: prevMat.id };
            const prevAvailable = materialToCompletion[prevMat.id].isAvailable;

            if (prevAvailable && prevMat.session.percent_progress >= 100) {
                nextElem.isAvailable = true;
            }

            materialToCompletion[material.id] = nextElem;
        }
        if (
            i === materialsList.length - 1 &&
            isQuizAvailable === false &&
            materialToCompletion[material.id]?.isAvailable
        ) {
            isQuizAvailable = material.session.percent_progress >= 100;
        }
    }
    if (
        (materialsList == undefined || materialsList?.length == 0) &&
        threat?.quiz
    ) {
        isQuizAvailable = true;
    }

    let threatProgress = 0;
    if (threat?.quiz_result_score > 0) {
        threatProgress = threat?.quiz_result_score;
    } else if (threat?.completed_content_count > 0) {
        threatProgress =
            threat?.completed_content_count / threat?.content_count;
    }

    function timeStringToMilliseconds(timeStr: string): number {
        const parts = timeStr.split(":").map(Number);
        if (parts.length !== 3) {
            throw new Error("Invalid time format. Please use 'hh:mm:ss'");
        }
        const [hours, minutes, seconds] = parts;
        if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
            throw new Error(
                "Invalid time format. Please use numbers for hours, minutes, and seconds."
            );
        }
        return ((hours * 60 + minutes) * 60 + seconds) * 1000;
    }

    function durationToMs() {
        const timeStr = threat.threat.threat_duration;

        try {
            const milliseconds = timeStringToMilliseconds(timeStr);
            // console.log(milliseconds);
            return milliseconds;
        } catch (error) {
            // console.error(error.message);
            return;
        }
    }

    const durationMs = durationToMs();

    const ddd = new Date(
        Date.now() + durationMs * (1 - threat.duration_percent)
    );

    const day = ddd.getDate();
    const mount = ddd.getMonth();
    const year = ddd.getFullYear();

    const dedline = `${day}.${mount}.${year}`;

    useEffect(() => {
        // if (isLoadingContent) {
        //     ModelApi3.loadFirstPage().then(() => {
        //         setIsLoadingContent(false);
        //     });
        // }
        if (openedThreat !== threat.threat.name) {
            setOpenMaterials(false);
        }
        if (Number.isInteger(days / 365) === true) {
            setTypeOfYear("год(лет)");
            setNumberTypeOfYear(days / 365);
        } else if (Number.isInteger(days / 30) === true) {
            setTypeOfYear("месяц(а)");
            setNumberTypeOfYear(days / 30);
        } else if (Number.isInteger(days / 7) === true) {
            setTypeOfYear("неделя(ь)");
            setNumberTypeOfYear(days / 7);
        } else if (Number.isInteger(days / 1) === true) {
            setTypeOfYear("день(дней)");
            setNumberTypeOfYear(days);
        }
    }, [threat, openMaterials, openedThreat]);

    // console.log(threat);

    return (
        <div
            className="m relative flex min-h-[195px] w-full max-w-[930px] cursor-pointer flex-col rounded border-[1px] border-white p-[20px] shadow-[0_0_10px_0_rgba(38,67,84,0.1)] hover:border hover:border-[#369F48]"
            onClick={openThreat}
        >
            {threat.content_count <= 0 && (
                <div className="absolute right-[-1px] top-[-1px] flex h-[25px] w-[130px] items-center justify-center rounded bg-[#F37D731A] text-tr-s font-normal text-[#F37D73]">
                    Недоступен
                </div>
            )}
            {selectedTypeList === "Lost" && threat.content_count > 0 && (
                <div className="absolute right-[-1px] top-[-1px] flex h-[25px] w-[130px] items-center justify-center rounded bg-[#F37D731A] text-tr-s font-normal text-[#F37D73]">
                    Просрочен
                </div>
            )}
            <div className="flex w-full">
                <div className="flex h-full flex-col items-center">
                    {threat.content_count > 0 ? (
                        <>
                            {selectedTypeList === "All" ||
                            selectedTypeList === "Oblivion" ||
                            selectedTypeList === "Fire" ? (
                                <ProgressCircle
                                    className="h-[80px] w-[80px]"
                                    colorLine="#369F48"
                                    colorText="#369F48"
                                    ringType="short"
                                    progress={threatProgress}
                                    visible={visible}
                                    typeOfYear={typeOfYear}
                                    numberTypeOfYear={numberTypeOfYear}
                                    selectedTypeList={selectedTypeList}
                                    dedline={dedline}
                                />
                            ) : (
                                <svg
                                    width="104"
                                    height="126"
                                    viewBox="0 0 104 126"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="absolute left-[5px] top-[-20px] "
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M29.624 96.271C23.4671 88.6273 25.1189 76.5713 31.3412 67.246C31.7085 74.774 33.6367 76.6229 37.7579 78.9534C35.0673 63.8455 36.5103 56.4366 48.9174 46.758C47.1365 50.9113 48.3891 59.9274 59.5189 66.5143C65.1196 69.6836 71.786 77.1143 71.2363 85.5388L71.2174 85.8306C70.9668 89.6935 70.6954 93.8756 68.2239 97.224C93.3171 87.3754 95.9292 60.3501 79.5546 35.7061C78.917 48.8259 75.5707 52.0482 68.4185 56.1097C73.0879 29.7799 70.5837 16.8676 49.0513 0C52.1421 7.23831 49.9682 22.9515 30.6525 34.4309C20.9326 39.9544 9.36307 52.9046 10.317 67.5866C11.1586 80.5387 17.4319 90.2503 29.624 96.271Z"
                                        fill="url(#paint0_linear_13070_78168)"
                                    />
                                    <rect
                                        x="12"
                                        y="46"
                                        width="80"
                                        height="80"
                                        rx="40"
                                        fill="white"
                                    />
                                    <path
                                        d="M52 72.668V86.0013L60.3333 94.3346"
                                        stroke="#F37D73"
                                        stroke-width="3"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M35.3307 57.1274C40.2336 54.2912 45.9259 52.668 51.9974 52.668C70.4069 52.668 85.3307 67.5918 85.3307 86.0013C85.3307 104.411 70.4069 119.335 51.9974 119.335C33.5879 119.335 18.6641 104.411 18.6641 86.0013C18.6641 79.9298 20.2873 74.2375 23.1235 69.3346"
                                        stroke="#F37D73"
                                        stroke-width="3"
                                        stroke-linecap="round"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="paint0_linear_13070_78168"
                                            x1="50.0715"
                                            y1="0"
                                            x2="50.0715"
                                            y2="97.224"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#FF4E26" />
                                            <stop
                                                offset="1"
                                                stop-color="#FFBEAF"
                                            />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            )}
                        </>
                    ) : (
                        <EmptyThreat className="h-[80px] w-[80px]" />
                    )}

                    {threat.content_count > 0 ? (
                        <div
                            className={` ${
                                selectedTypeList === "Loosed" ||
                                selectedTypeList === "All" ||
                                selectedTypeList === "Oblivion" ||
                                selectedTypeList === "Fire"
                                    ? "ml-[35px] mt-[83px] flex h-full pt-[20px]"
                                    : " h-full"
                            }`}
                        >
                            <ArrowComponent />
                        </div>
                    ) : (
                        <svg
                            width="8"
                            height="48"
                            viewBox="0 0 8 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mt-[25px]"
                        >
                            <path
                                d="M3.64645 47.3536C3.84171 47.5488 4.15829 47.5488 4.35356 47.3536L7.53554 44.1716C7.7308 43.9763 7.7308 43.6597 7.53554 43.4645C7.34027 43.2692 7.02369 43.2692 6.82843 43.4645L4 46.2929L1.17157 43.4645C0.976313 43.2692 0.65973 43.2692 0.464468 43.4645C0.269206 43.6597 0.269206 43.9763 0.464468 44.1716L3.64645 47.3536ZM3.5 2.18557e-08L3.5 2.35L4.5 2.35L4.5 -2.18557e-08L3.5 2.18557e-08ZM3.5 7.05L3.5 11.75L4.5 11.75L4.5 7.05L3.5 7.05ZM3.5 16.45L3.5 21.15L4.5 21.15L4.5 16.45L3.5 16.45ZM3.5 25.85L3.5 30.55L4.5 30.55L4.5 25.85L3.5 25.85ZM3.5 35.25L3.5 39.95L4.5 39.95L4.5 35.25L3.5 35.25ZM3.5 44.65L3.5 47L4.5 47L4.5 44.65L3.5 44.65Z"
                                fill="#ADADAD"
                            />
                        </svg>
                    )}
                </div>
                <div
                    className={`${
                        (selectedTypeList === "All" ||
                            selectedTypeList === "Oblivion" ||
                            selectedTypeList === "Loosed" ||
                            selectedTypeList === "Fire") &&
                        threat.content_count > 0
                            ? " ml-[55px] "
                            : " ml-[20px] "
                    }flex h-full w-full flex-col justify-between`}
                >
                    <div className="flex flex-col">
                        <div className="flex w-full justify-between pt-[30px]">
                            <p
                                className={`text-[16px] font-medium leading-[19.2px] ${
                                    available ? " " : "text-gray-500 opacity-60"
                                }`}
                            >
                                {truncateText(threat?.threat?.name, 2)}
                            </p>
                        </div>
                        {selectedTypeList === "Fire" && (
                            <div className="mt-[30px] flex items-center gap-x-[5px]">
                                <svg
                                    width="23"
                                    height="28"
                                    viewBox="0 0 23 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g filter="url(#filter0_d_13058_170)">
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M8.12851 23.4949C7.06288 21.947 7.34878 19.5057 8.42571 17.6173C8.48929 19.1417 8.82302 19.5161 9.53629 19.9881C9.07062 16.9287 9.32036 15.4284 11.4677 13.4685C11.1595 14.3095 11.3763 16.1353 13.3026 17.4691C14.272 18.1109 15.4258 19.6157 15.3306 21.3216L15.3274 21.3807C15.284 22.1629 15.237 23.0098 14.8093 23.6879C19.1523 21.6935 19.6044 16.2209 16.7703 11.2305C16.66 13.8872 16.0808 14.5398 14.8429 15.3622C15.6511 10.0304 15.2177 7.4157 11.4909 4C12.0259 5.46576 11.6496 8.64767 8.30652 10.9723C6.62422 12.0908 4.62181 14.7132 4.78692 17.6863C4.93258 20.3091 6.01834 22.2757 8.12851 23.4949Z"
                                            fill="url(#paint0_linear_13058_170)"
                                        />
                                    </g>
                                    <defs>
                                        <filter
                                            id="filter0_d_13058_170"
                                            x="-1"
                                            y="0"
                                            width="26"
                                            height="28.25"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB"
                                        >
                                            <feFlood
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="out"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 1 0 0 0 0 0.305882 0 0 0 0 0.14902 0 0 0 0.25 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="BackgroundImageFix"
                                                result="effect1_dropShadow_13058_170"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="effect1_dropShadow_13058_170"
                                                result="shape"
                                            />
                                        </filter>
                                        <linearGradient
                                            id="paint0_linear_13058_170"
                                            x1="11.6675"
                                            y1="4"
                                            x2="11.6675"
                                            y2="23.6879"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#FF4E26" />
                                            <stop
                                                offset="1"
                                                stop-color="#FFBEAF"
                                            />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <p className="mt-[4px] text-tr-s font-medium text-[#264354]">
                                    Навык рекомендуется
                                </p>
                                <p className="mt-[4px] text-tr-s font-medium text-[#F37D73]">
                                    освоить до {dedline}
                                </p>
                            </div>
                        )}
                        {selectedTypeList === "Loosed" &&
                            threat.content_count > 0 && (
                                <div className="mt-[30px] flex items-center gap-x-[5px]">
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clip-path="url(#clip0_12019_252644)">
                                            <path
                                                d="M10 6.66699V10.0003L12.0833 12.0837"
                                                stroke="#F37D73"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M5.83463 2.78184C7.06036 2.0728 8.48344 1.66699 10.0013 1.66699C14.6037 1.66699 18.3346 5.39795 18.3346 10.0003C18.3346 14.6027 14.6037 18.3337 10.0013 18.3337C5.39893 18.3337 1.66797 14.6027 1.66797 10.0003C1.66797 8.48246 2.07378 7.05938 2.78282 5.83366"
                                                stroke="#F37D73"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_12019_252644">
                                                <rect
                                                    width="20"
                                                    height="20"
                                                    fill="white"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    <p className="mt-[4px] text-tr-s font-medium text-[#F37D73]">
                                        Срок истек {dedline}
                                    </p>
                                </div>
                            )}
                    </div>

                    {openMaterials === false ? (
                        <div
                            onClick={(e) => e.stopPropagation()}
                            className="flex w-full justify-between"
                        >
                            {threat.content_count > 0 ? (
                                <div
                                    className={` ${
                                        available
                                            ? " "
                                            : "text-gray-500 opacity-60"
                                    } flex items-end gap-x-[10px]`}
                                    onClick={openThreat}
                                >
                                    <p className="text-[14px] font-medium leading-[16.8px] text-[#264354] underline">
                                        Чтобы освоить навык, необходимо изучить
                                    </p>
                                    <div className="flex h-[25px] min-w-[80px] items-center justify-center gap-x-[10px] rounded bg-[#26435433] px-[20px]">
                                        <p className="text-[12px] font-normal leading-[14.4px] text-[#264354]">
                                            {threat.content_count || 0}{" "}
                                            материалов
                                        </p>
                                        &#8595;
                                    </div>
                                </div>
                            ) : (
                                <p className="text-[14px] font-medium leading-[16.8px] text-[#264354] underline">
                                    Этот навык находится на стадии формирования
                                </p>
                            )}

                            {threat.content_count > 0 && (
                                <div
                                    onClick={() =>
                                        handleClickLinkThreat(threat.id)
                                    }
                                    className={`flex h-[35px] w-full max-w-[185px] items-center justify-center gap-x-[10px] rounded-full ${
                                        available
                                            ? "text-[#566DA3] hover:bg-[#566DA3] hover:text-white"
                                            : "text-gray-500 opacity-60"
                                    } shadow-[0_0_10px_0_rgba(38,67,84,0.1)] `}
                                >
                                    <p className="text-tr-s font-medium">
                                        Изучить сразу
                                    </p>
                                    &#10230;
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            {materialsStatus === "success" ? (
                                <>
                                    {materialsList.length > 0 ? (
                                        <div className="relative mt-[45px] flex flex-col">
                                            <div className="flex max-w-[775px] items-end justify-between">
                                                <div className="flex items-end gap-x-[10px]">
                                                    <p className="mt-[19px] text-[14px] font-medium leading-[16.8px] text-[#264354] underline">
                                                        Чтобы освоить навык,
                                                        необходимо изучить
                                                    </p>
                                                    <div className="flex h-[25px] min-w-[80px] items-center justify-center gap-x-[10px] rounded bg-[#26435433] px-[20px]">
                                                        <p className="text-[12px] font-normal leading-[14.4px] text-[#264354]">
                                                            {threat.content_count ||
                                                                0}{" "}
                                                            материалов
                                                        </p>
                                                        &#8593;
                                                    </div>
                                                </div>
                                                <div
                                                    onClick={() =>
                                                        handleClickLinkThreat(
                                                            threat.id
                                                        )
                                                    }
                                                    className="mr-[-15px] flex h-[35px] w-full max-w-[185px] items-center justify-center gap-x-[10px] rounded-full text-[#566DA3] shadow-[0_0_10px_0_rgba(38,67,84,0.1)] hover:bg-[#566DA3] hover:text-white"
                                                >
                                                    <p className="text-tr-s font-medium">
                                                        Изучить сразу
                                                    </p>
                                                    &#10230;
                                                </div>
                                            </div>
                                            <div className="relative mt-[30px] flex flex-col gap-[20px]">
                                                {materialsList.map(
                                                    (material, index) => (
                                                        <MaterialCard
                                                            material={material}
                                                            setEditMaterial={
                                                                setEditMaterial
                                                            }
                                                            threatId={threat.id}
                                                            contentList={
                                                                materialsList
                                                            }
                                                            index={index}
                                                            threatOrdered={
                                                                threat.is_ordered
                                                            }
                                                            available={
                                                                materialToCompletion[
                                                                    material.id
                                                                ].isAvailable
                                                            }
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flex h-full w-full flex-col justify-center">
                                            <div className="flex flex-col items-center gap-y-[20px]">
                                                <p className="mb-[20px] text-center text-[16px] font-semibold leading-[19.2px] text-[#F37D73]">
                                                    Упс, кажется тут пусто.{" "}
                                                    <br />
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className="flex h-[65px] w-full items-center justify-center">
                                    <LoaderConstructor />
                                </div> // Лоадер
                            )}
                        </>
                    )}
                </div>
                {threat.content_count > 0 && (
                    <>
                        {activeOrdered === true && (
                            <div className="ml-[10px] mt-[14px] flex flex-col items-center gap-y-[15px]">
                                <svg
                                    width="18"
                                    height="17"
                                    viewBox="0 0 18 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-[20px] w-[20px]"
                                >
                                    <path
                                        d="M11.2646 2.37603C12.4498 1.18617 14.2754 1.08972 15.3421 2.16058C16.4089 3.23145 16.3128 5.06411 15.1275 6.25396L13.4106 7.97751M7.6162 9.9165C6.54948 8.84564 6.64556 7.01296 7.83081 5.8231L9.35417 4.29384"
                                        stroke="#F37D73"
                                        stroke-width="1.0625"
                                        stroke-linecap="round"
                                    />
                                    <path
                                        d="M10.3831 7.0835C11.4499 8.15437 11.3538 9.98701 10.1685 11.1769L8.45167 12.9004L6.7348 14.624C5.54954 15.8138 3.72395 15.9103 2.65722 14.8394C1.59049 13.7685 1.68658 11.9359 2.87183 10.746L4.58872 9.02245"
                                        stroke="#F37D73"
                                        stroke-width="1.0625"
                                        stroke-linecap="round"
                                    />
                                </svg>
                                {index + 1 !== threatListLength &&
                                activeOrdered === true ? (
                                    <div
                                        className={`h-full ${
                                            openMaterials
                                                ? " "
                                                : " max-h-[105px]"
                                        } `}
                                    >
                                        <ArrowDependence />
                                    </div>
                                ) : (
                                    <div
                                        className={`h-full ${
                                            openMaterials
                                                ? " "
                                                : " max-h-[105px]"
                                        } `}
                                    >
                                        <ArrowDependenceEnd />
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>
            {threat.quiz !== null && openMaterials === true && (
                <TestCard
                    editThreat={threat}
                    isQuizAvailable={isQuizAvailable}
                    setOpenPopupTest={setOpenPopupTest}
                />
            )}
            {!openPopupTest ? (
                ""
            ) : (
                <PopupTest
                    session={null}
                    quiz={threat?.quiz?.id}
                    setOpenPopupTest={setOpenPopupTest}
                    typeQuiz="threat"
                />
            )}
        </div>
    );
};
