import { useState, useEffect } from "react";
import * as types from "shared/types";
import { ButtonStartTest } from "components/ButtonStartTest/ButtonStartTest";
import { ReactComponent as Restart } from "shared/ui/icon/Restart.svg";
import Counter from "./Counter";
import { throttle } from "shared/throttle";
import { commitProgress } from "utils/commitProgress";
import { PopupTest } from "components/PopupTest/PopupTest";
import { TestMaterial } from "components/PopupTest/TestMaterial ";
import "ckeditor5/ckeditor5-content.css";
import { useQuery } from "@tanstack/react-query";
import * as http from "shared/http";

function VideoAdapter(props: {
    src: string;
    title: string;
    session: number | string;
    openPopupTest: boolean;
    quiz: number;
    setOpenPopupTest: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const [isVideoWatched, setIsVideoWatched] = useState(false);

    const handleClickLink = () => {
        props.setOpenPopupTest(!props.openPopupTest);
    };

    const handleProgressChange = throttle(function (e: Event) {
        const target = e.target as HTMLVideoElement;

        if (Math.round(target.currentTime) === Math.round(target.duration)) {
            setIsVideoWatched(true);
            commitProgress(
                props.session,
                Math.round((target.currentTime / target.duration) * 100)
            );
        }
    });

    const handleClickRestart = () => {
        setIsVideoWatched(false);
        document.querySelector("video")?.classList.add("blur-none");
        document.querySelector("video")?.classList.remove("blur-sm");
        document.querySelector(".button")?.classList.add("hidden");
        document.querySelector(".button")?.classList.remove("flex");
        document.querySelector(".button")?.classList.remove("gap-[60px]");
        document.querySelector("video")?.setAttribute("controls", "true");
        document.querySelector("video")?.play();
    };

    useEffect(() => {
        if (isVideoWatched) {
            document.querySelector("video")?.classList.remove("blur-none");
            document.querySelector("video")?.classList.add("blur-sm");
            document.querySelector(".button")?.classList.remove("hidden");
            document.querySelector(".button")?.classList.add("flex");
            document.querySelector(".button")?.classList.add("gap-[60px]");
            document.querySelector("video")?.removeAttribute("controls");
        }
    }, [isVideoWatched]);

    return (
        <>
            <div className="button absolute left-1/2 top-1/2 z-10 hidden -translate-x-1/2 -translate-y-1/2 transform flex-col">
                <div className="flex flex-col gap-[10px]">
                    <p className="text-[20px] font-bold leading-[24px] text-[#FFFFFF]">
                        Смотреть сначала
                    </p>
                    <button
                        className="flex justify-center"
                        onClick={handleClickRestart}
                    >
                        <Restart />
                    </button>
                </div>
                {props.quiz && (
                    <ButtonStartTest
                        text="Пройти тест"
                        handleClickLink={handleClickLink}
                    />
                )}
            </div>
            <video
                title={props.title}
                className="grow"
                src={props.src}
                controls
                onTimeUpdate={handleProgressChange}
            ></video>
        </>
    );
}

function ContentAdapter(props: {
    material: types.Content;
    mutate: Function;
    session: number | string;
    openPopupTest: boolean;
    setOpenPopupTest: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const handleClickLink = () => {
        props.setOpenPopupTest(!props.openPopupTest);
    };

    const {
        data: articleData,
        error: articleError,
        status: articleStatus,
    } = useQuery({
        queryKey: ["article", props.material.id],
        queryFn: async () => {
            const response = await http.request<any>({
                url: `/api/v1/content/article/${props.material.id}`,
            });
            return response;
        },
        enabled: props.material.content_type.model === "articlecontent",
    });

    console.log(articleData);
    switch (props.material.content_type.model) {
        case "meeting":
            if (props.material.is_running) {
                return (
                    <iframe
                        title={props.material.content_type.model}
                        className="h-[500px] w-full"
                        allow="camera *;microphone *;display-capture *"
                        allowFullScreen
                        src={props.material.url}
                    />
                );
            } else {
                return (
                    <Counter
                        startTime={props.material.start_datetime}
                        mutate={props.mutate}
                        materialId={props.material.id}
                    />
                );
            }
        case "scormpackage":
            if (props?.material?.quiz === null) {
                commitProgress(props.session, 100);
            }

            return (
                <>
                    <iframe
                        title={props.material.content_type.model}
                        className="h-full w-full bg-white"
                        src={
                            "/ui/scorm.html?data=" +
                            encodeURIComponent(JSON.stringify(props.material))
                        }
                    ></iframe>
                    {props?.material?.quiz !== null && (
                        <div className="absolute bottom-5 left-5 flex flex-col gap-[20px]">
                            {/* <p className="text-[20px] font-bold leading-[24px] text-[#264354]">
                                Проверочное задание
                            </p> */}
                            <ButtonStartTest
                                text="Пройти тест"
                                handleClickLink={handleClickLink}
                            />
                        </div>
                    )}
                </>
            );
        case "videocontent":
            return (
                <VideoAdapter
                    src={props.material.url || props.material.file}
                    title={props.material.content_type.model}
                    quiz={props.material.quiz}
                    session={props.session}
                    openPopupTest={props.openPopupTest}
                    setOpenPopupTest={props.setOpenPopupTest}
                />
            );
        case "testcontent":
            return (
                <TestMaterial
                    session={props.session}
                    quiz={props.material.quiz}
                />
            );
        case "webresourcecontent":
        case "pdfcontent":
        case "articlecontent":
            if (props?.material?.quiz === null) {
                commitProgress(props.session, 100);
            }
            return (
                <>
                    {/* <iframe
                        title={props.material.content_type.model}
                        className="h-full min-h-[500px] w-full"
                        src={props.material.url}
                    ></iframe> */}
                    {articleStatus === "success" && (
                        <div
                            className="ck-content"
                            dangerouslySetInnerHTML={{
                                __html: articleData || "",
                            }}
                        />
                    )}
                    {props?.material?.quiz !== null && (
                        <div className="absolute bottom-5 left-5 flex flex-col gap-[20px]">
                            {/* <p className="text-[20px] font-bold leading-[24px] text-[#264354]">
                                Проверочное задание
                            </p> */}
                            <ButtonStartTest
                                text="Пройти тест"
                                handleClickLink={handleClickLink}
                            />
                        </div>
                    )}
                </>
            );
        default:
            return (
                <div className="rounded-[5px] bg-white p-[15px]">
                    Пока не поддерживается
                </div>
            );
    }
}

export default ContentAdapter;
